import { capitalize } from '@helpers'
import { Image } from '@components/products/products'
import { API_URL } from '@constants'
import axiosReadInstance from '../../request/axiosReadInstance'
import { useEffect, useState } from 'react'
import logoLt from '../../assets/logo-lt.svg'
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/solid'
import { Button } from '@components/button/button'
interface Socials {
    id: string
    name: string
    url: string
    logo: Image
}

export interface IPath {
    id: string
    attributes: {
        url: string
        name: string
        position: number
    }
}

interface PagesResult {
    data: Array<IPath>
}

interface IFooter {
    copyrightText: string
    workplaceLogo: Image
    lovelyText: string
    slogan: string
    recommendationLogo: Image
    socials: Array<Socials>
    buttonText: string
    buttonUrl: string
    legalNotice: string
    accessibilityText: string
}
export const Footer = () => {
    const [footer, setFooter] = useState<IFooter>()

    const [loading, setLoading] = useState<boolean>(false)
    const [data, setData] = useState<PagesResult>()

    const fetchPagesData = async () => {
        setLoading(true)
        await axiosReadInstance
            .get(`${API_URL}/api/pages`, {
                params: {
                    sort: 'position:asc',
                    fields: ['url', 'name', 'position'],
                },
            })
            .then((response) => {
                const data = response.data
                setData(data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        fetchData()
        fetchPagesData()
    }, [])

    const fetchData = async () => {
        await axiosReadInstance
            .get(`${API_URL}/api/footers?populate=deep`)
            .then((response) => {
                setFooter(response.data.data[0].attributes)
            })
    }
    const slogan = footer?.slogan.split('?')
    const year = new Date().getFullYear()
    useEffect(() => {
        fetchData()
    }, [])

    return (
        <footer
            className="lg:text-left text-gray-600"
            style={{ background: '#F7F7F8' }}
        >
            <div className="lg:text-left text-gray-600">
                <div className="mx-6 py-4 text-left">
                    <div className="md:grid md:grid-col-1 gap-1">
                        <div className="flex flex-col md:flex-row justify-between">
                            <div>
                                <h6
                                    className="
                                font-semibold
                                mb-4
                                flex
                                md:items-center
                                md:justify-start
                            "
                                >
                                    <img
                                        src={logoLt}
                                        alt="Logo Listen too"
                                        translate="no"
                                    />
                                    Listen too
                                </h6>
                                {slogan && slogan?.length > 0 && (
                                    <>
                                        <p
                                            style={{ fontSize: 14 }}
                                        >{`${slogan[0]}?`}</p>
                                        <p
                                            style={{ fontSize: 14 }}
                                        >{`${slogan[1]}?`}</p>
                                    </>
                                )}

                                <Button
                                    variant="secondary"
                                    href={footer?.buttonUrl}
                                    classList="inline-block mt-4 mb-4"
                                >
                                    {footer?.buttonText}
                                </Button>
                            </div>
                            <div className="flex mb-4">
                                <div className="text-left md:text-center">
                                    <div
                                        style={{
                                            display: 'inline-block',
                                            textAlign: 'left',
                                        }}
                                    >
                                        {loading ? (
                                            <div className="hidden"></div>
                                        ) : (
                                            data?.data.map((path, index) => (
                                                <Button
                                                    variant="link"
                                                    href={path.attributes.url.toLowerCase()}
                                                    classList="block mb-4"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    key={index}
                                                    title={capitalize(
                                                        path.attributes.name
                                                    )}
                                                >
                                                    {capitalize(
                                                        path.attributes.name
                                                    )}
                                                </Button>
                                            ))
                                        )}
                                    </div>
                                </div>
                                <div
                                    className="ml-12 md:ml-1"
                                    style={{ textAlign: 'left' }}
                                >
                                    {footer?.socials.map((social, index) => (
                                        <Button
                                            variant="link"
                                            icon={
                                                <ArrowTopRightOnSquareIcon className="w-4" />
                                            }
                                            iconPosition="after"
                                            href={social.url}
                                            classList="mb-4"
                                            target="_blank"
                                            rel="noreferrer"
                                            logo={
                                                social.logo.data.attributes.url
                                            }
                                            key={index}
                                        >
                                            {capitalize(social.name)}
                                        </Button>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="flex text-center md:text-left justify-between">
                                <p style={{ fontSize: '12px' }}>
                                    © {year} Listen too
                                </p>
                                <p
                                    className="text-center md:text-left "
                                    style={{ fontSize: '12px' }}
                                >
                                    {footer?.lovelyText}
                                </p>
                                <a
                                    href="/privacy-policy"
                                    className="hover:underline hover:underline-offset-2 text-center md:text-left"
                                    style={{ fontSize: '12px' }}
                                >
                                    {footer?.legalNotice}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}
