import parse from 'html-react-parser';
import { highlight } from '@helpers';
import { SocialNetworksList } from '@components';

interface TitleAndSocialsProps {
    title: string;
}

const TitleAndSocials = ({ title }: TitleAndSocialsProps) => {
    return (
        <div className="lg:px-20 lg:py-10 sm:px-10 sm:py-5 justify-self-center md:w-[500px] lg:absolute left-0 lg:order-1 sm:static sm:order-2 sm:text-center">
            <h1 className="text-left font-semibold tracking-tight leading-none text-gray-900 lg:text-7xl sm:text-xl">
                {title && parse(title.replace('avec nous !', highlight('avec nous !')))}
            </h1>
            <SocialNetworksList />
        </div>
    );
};

export default TitleAndSocials;
