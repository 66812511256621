import Select from 'react-select';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import DropdownIndicator from './dropdownIndicator';
import { ContactMessage, Validation } from '@types';

interface CitySelectProps {
    message: ContactMessage;
    cityOptions: any;
    validation: Validation;
    handleSelectChange: (event: React.EventHandler<any>) => void;
}

const CitySelect = ({ message, cityOptions, validation, handleSelectChange } : CitySelectProps) => {
    return (
        <div>
            <div className="flex w-fit ml-auto my-2">
                {message.content}
            </div>
            <label
                className="flex w-fit ml-auto my-2 text-sm"
                style={{ color: 'rgb(156 163 175)' }}
                htmlFor={`contact-${message.title}`}
            >
                {message.infoText}
            </label>
            <div className="flex flex-col w-fit ml-auto mb-4">
                <div className="flex w-fit ml-auto my-2">
                    <div>
                        <div style={{ height: '0', marginLeft: '2.6rem', visibility: 'hidden' }}>
                            {message.field}
                        </div>
                        <Select
                            id={`contact-${message.title}`}
                            name={message.title}
                            isSearchable={false}
                            hideSelectedOptions={true}
                            onChange={(event) => handleSelectChange(event)}
                            unstyled
                            components={{ DropdownIndicator }}
                            classNames={{
                                singleValue: () => 'single-value',
                                control: () => 'py-2 px-2 mb-2 border-2 border-secondary-300',
                                menu: () => 'border-2 border-secondary-300',
                                option: () => 'w-max city-choice pr-2.5',
                            }}
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    backgroundColor: '#F7F7F8',
                                }),
                                dropdownIndicator: (base, state) => ({
                                    ...base,
                                    transition: 'all .4s ease',
                                    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'initial',
                                }),
                                option: (provided) => ({
                                    ...provided,
                                    backgroundColor: '#F7F7F8',
                                    textAlign: 'end',
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    paddingRight: '0.625rem',
                                }),
                                menu: (provided) => ({
                                    ...provided,
                                    background: '#F7F7F8',
                                }),
                            }}
                            formatOptionLabel={(data) => (
                                <div className="city-choiceOption rounded px-1" style={{ width: 'fit-content' }}>
                                    {data.attributes.label}
                                </div>
                            )}
                            options={cityOptions}
                            getOptionLabel={(option) => `${option.attributes.label}`}
                            getOptionValue={(option) => `${option.id}`}
                            placeholder={<div className="city-placeholder">{message.field}</div>}
                        />
                        <span>
                            {validation[message.title as keyof typeof validation] !== '' && (
                                <div className="flex items-center text-state-error">
                                    <ExclamationCircleIcon className="mr-1 w-6" />
                                    {validation[message.title as keyof typeof validation]}
                                </div>
                            )}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CitySelect;
