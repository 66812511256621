import { components, DropdownIndicatorProps } from 'react-select'

const DropdownIndicator = (props: DropdownIndicatorProps<any>) => {
    return (
        <components.DropdownIndicator className="ml-2" {...props}>
            <svg
                width="13"
                height="10"
                viewBox="0 0 13 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M5.92679 0.902875C6.1262 0.623697 6.54112 0.623696 6.74053 0.902874L12.4356 8.87607C12.672 9.20701 12.4354 9.66669 12.0287 9.66669H0.63858C0.231895 9.66669 -0.00466704 9.20701 0.231712 8.87608L5.92679 0.902875Z"
                    fill="#0A8335"
                />
            </svg>
        </components.DropdownIndicator>
    )
}

export default DropdownIndicator;