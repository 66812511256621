import {
    WhoWeAreHeader,
    History,
    OurValues,
    Vision,
    CommitmentList,
    RSECommitment,
} from '@components'
import { Helmet } from 'react-helmet-async'

export const WhoWeAre = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Listen too | Qui sommes-nous - Conseil en digitalisation </title>
                <meta name="description" content="Découvrez notre agence de conseil en digitalisation, spécialisée en relation client et collaborateur, à la vision innovante et aux valeurs fortes" />
            </Helmet>
            <WhoWeAreHeader />
            <History />
            <OurValues />
            <Vision />
            <CommitmentList/>
            <RSECommitment />
        </>
    )
}
