import { API_URL } from '@constants'
import axiosReadInstance from '../../request/axiosReadInstance'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import { useEffect, useState } from 'react'
import { usePageLoading } from '../../context/PageLoadingContext'

interface IVision {
    content: string
    title: string
}

export const Vision = () => {
    const [vision, setVision] = useState<IVision>();
    const markdown = `${vision?.content}`;
    const { incrementLoadingRequestCount, decrementLoadingRequestCount } = usePageLoading();

    const fetchData = async () => {
        incrementLoadingRequestCount();
        await axiosReadInstance
            .get(`${API_URL}/api/vision?populate=deep`)
            .then((response) => {
                setVision(response.data.data.attributes)
            })
            .finally(() => {
                decrementLoadingRequestCount();
            });
    }
    useEffect(() => {
        fetchData()
    }, [])

    return (
        <>
            <section className="section-style">
                <div className="lg:pt-6 lg:pb-16 md:px-[8rem]">
                    
                    <h1 className="h1-title">{vision?.title}</h1>
                    <ReactMarkdown
                        className="h1-subtext"
                        rehypePlugins={[rehypeRaw]}
                    >
                        {markdown || ''}
                    </ReactMarkdown>

                    <p className="text-left text-base"></p>
                </div>
            </section>
        </>
    )
}
