import { useEffect, useState } from 'react';
import { BrowserRouter, useLocation } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import App from './App';
import { ErrorGlobal } from '@layouts';
import { validateEnvVariables, validateApiCredentials } from '@utils';
import { PageLoadingProvider } from './context/PageLoadingContext';

const AppWrapper: React.FC = () => {
  const [isValidConfig, setIsValidConfig] = useState<boolean | null>(null);

  useEffect(() => {
    const validateConfig = async () => {
      if (validateEnvVariables()) {
        const isApiValid = await validateApiCredentials();
        setIsValidConfig(isApiValid);
      } else {
        setIsValidConfig(false);
      }
    };

    validateConfig();
  }, []);

  if (isValidConfig === null) {
    return null;
  }

  return (
    <BrowserRouter>
      <HelmetProvider>
        <PageLoadingProvider>
        {isValidConfig ? <ValidatedApp /> : <ErrorGlobal />}
        </PageLoadingProvider>
      </HelmetProvider>
    </BrowserRouter>
  );
};

const ValidatedApp: React.FC = () => {
  const [isValidConfig, setIsValidConfig] = useState<boolean>(true);
  const location = useLocation();

  useEffect(() => {
    const validateApi = async () => {
      const isApiValid = await validateApiCredentials();
      setIsValidConfig(isApiValid);
    };

    validateApi();
  }, [location]);

  return isValidConfig ? <App /> : <ErrorGlobal />;
};

export default AppWrapper;
