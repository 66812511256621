import {
    JoinUs,
    Hero,
    CompanyNumbers,
    Products,
    Trust,
    Testimonials,
    Offices,
    Articles,
} from '@components'
import { ExpertisesCards } from '@components/expertisesCards/ExpertisesCards'
import { Helmet } from 'react-helmet-async'

export const Home = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Listen too | Accueil - Agence conseil en digitalisation</title>
                <meta name="description" content="Agence spécialisée en digitalisation de la relation client et collaborateur. Experts en fidélité, e-commerce, conversationnel, selfcare et CRM. " />
            </Helmet>
            <Hero />
            <CompanyNumbers />
            <ExpertisesCards />
            <Products />
            <JoinUs />
            <Trust />
            <Testimonials />
            <Offices />
            <Articles title={true} />
        </>
    )
}
