import ReactDOM from 'react-dom/client';
import './style.scss';
import AppWrapper from './AppWrapper';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <AppWrapper />
);

reportWebVitals();
