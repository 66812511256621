import { Button } from '@components/button/button'
import { Image } from '@components/products/products'
import { API_URL } from '@constants'
import axiosReadInstance from '../../request/axiosReadInstance'
import { useEffect, useState } from 'react'
import { usePageLoading } from '../../context/PageLoadingContext'

interface IJoinUs {
    title: string
    description: string
    primaryAction: string
    secondaryAction: string
    primaryLink: string
    secondaryLink: string
    background: Image
}

export const JoinUs = () => {
    const [joinUs, setJoinUs] = useState<IJoinUs>();
    const { incrementLoadingRequestCount, decrementLoadingRequestCount } = usePageLoading();
    const fetchData = async () => {
        incrementLoadingRequestCount();
        await axiosReadInstance
            .get(`${API_URL}/api/join-uses/1?populate=%2A`)
            .then((response) => setJoinUs(response.data.data.attributes))
            .finally(() => {
                decrementLoadingRequestCount();
            });
    }

    useEffect(() => {
        fetchData()
    }, [])

    const backgroundImage = {
        backgroundImage:
            'url(' + joinUs?.background.data.attributes.url + ')',
    }
    return (
        <section
            className="mt-6 bg-no-repeat bg-center bg-center"
            style={backgroundImage}
        >
            <div className="relative max-w-[670px] mx-auto px-6 sm:px-10 py-20 text-center">
                <h2 className="h2 mb-6">
                    {joinUs?.title}
                </h2>

                <p className="md:paragraph text-neutral-500 text-sm md:text-base lg:text-lg">
                    {joinUs?.description}
                </p>

                <div className="mt-4 flex flex-wrap justify-center gap-4 mb-8">
                    <Button
                        href={`${joinUs?.primaryLink || '/'}`}
                        target="_blank"
                        rel="noopener noreferrer">
                        {joinUs?.primaryAction}
                    </Button>

                    <Button
                        variant='secondary'
                        href={`mailto:contact@listen-too.com`}>
                        {joinUs?.secondaryAction || 'Envoyer un email'}
                    </Button>
                </div>
            </div>
        </section>
    )
}
