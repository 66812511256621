import axiosContactInstance from '../../request/axiosContactInstance'
import { API_URL } from '@constants'

export const sendEmail = async (data: any): Promise<void> => {
    try {
        await axiosContactInstance.post(`${API_URL}/api/emails/send`, data);
    } catch (error: any) {
        throw new Error(`Error while sending email: ${error}`)
    }
}
