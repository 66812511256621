import axios from 'axios'

const authToken = process.env.REACT_APP_API_CONTACT_FORM_TOKEN;

const axiosContactInstance = axios.create()

axiosContactInstance.interceptors.request.use(
    (config: any) => {
        if (authToken) {
            config.headers['Authorization'] = `Bearer ${authToken}`
        }
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

export default axiosContactInstance
