import { API_URL } from '@constants'
import axiosReadInstance from '../../request/axiosReadInstance'
import { useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import { usePageLoading } from '../../context/PageLoadingContext'
interface SupportType {
    title: string
    content: string
}

interface ISupport {
    title: string
    supportType: Array<SupportType>
}

export const Support = () => {
    const [support, setSupport] = useState<ISupport>();
    const { incrementLoadingRequestCount, decrementLoadingRequestCount } = usePageLoading();

    const fetchData = async () => {
        incrementLoadingRequestCount();
        await axiosReadInstance
            .get(`${API_URL}/api/support?populate=deep`)
            .then((response) => {
                setSupport(response.data.data.attributes)
            })
            .finally(() => {
                decrementLoadingRequestCount();
            });
    }

    useEffect(() => {
        fetchData()
    }, [])
    return (
        <section className="bg-white">
            <div className="py-8 px-8 mx-auto text-left lg:py-16 xl:pl-[8rem] xl:pr-[16rem]">
                <h2 className="mb-4 text-2xl font-semibold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-1xl   ">
                    {support?.title}
                </h2>
                <div className="relative">
                    <div
                        // mt-10 justify-center relative flex gap-1 overflow-hidden scroll-smooth px-[70px]  snap-x snap-mandatory touch-pan-x z-0
                        className={`mt-6 gap-[1px]`}
                        style={{ backgroundColor: '#D6D6DC' }}
                    >
                        {support?.supportType.map((supportType, index) => (
                            <div key={index}>
                                <div
                                    className="w-full h-[25px]"
                                    style={{ backgroundColor: 'white' }}
                                />
                                <div
                                    className="flex"
                                    style={{ backgroundColor: 'white' }}
                                >
                                    <div
                                        
                                        className={`md:border-l relative w-full self-center md:px-4 md:pl-[77px] py-1 snap-start`}
                                        style={{ borderColor: '#E9E9EC' }}
                                    >
                                        <h3
                                            className="text-2xl font-semibold text-left font-semibold font-primary tracking-tight text-gray-900"
                                            style={{ color: '#0A8335' }}
                                        >
                                            {supportType.title}
                                        </h3>
                                        <div className="text-justify mt-4">
                                            <ReactMarkdown className='prose  text-sm md:text-base' rehypePlugins={[rehypeRaw]} children={supportType.content } /> 
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="w-full h-[25px]"
                                    style={{ backgroundColor: 'white' }}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}
