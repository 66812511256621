import { BlogDetail } from '@components'
import { Helmet } from 'react-helmet-async'

export const BlogDetailPage = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Listen too | Détails du blog - Insights en Digitalisation </title>
                <meta name="description" content="Lisez notre dernier article de blog sur la digitalisation de la relation client et collaborateur. Explorez des études de cas, des conseils et des stratégies innovantes." />
            </Helmet>
            <BlogDetail />
        </>
    )
}
