import { useRef, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import logoLt from '../../assets/logo-lt.svg'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/solid'
import axiosReadInstance from '@request/axiosReadInstance'
import { API_URL } from '@constants'
import { hideMarkedText } from '@helpers/getMark'
import { useClickOutside } from '../../hooks'

interface Path {
    id: number
    attributes: {
        url: string
        name: string
        position: number
    }
}

interface ExpertisePage {
    id: number
    attributes: {
        title: string
        urlSlug: string
    }
}

interface PagesResult {
    data: Array<Path>
}

export const Navbar = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [data, setData] = useState<PagesResult>()
    const [expertisePages, setExpertisePages] = useState<ExpertisePage[]>([])

    const dropdownRef = useRef<HTMLDivElement>(null)
    const buttonRef = useRef<HTMLDivElement>(null)

    const toggleNavbar = () => {
        setIsOpen(!isOpen)
    }

    const toggleDropdownMobile = () => {
        if (window.innerWidth < 1024) {
            setDropdownOpen(!dropdownOpen)
        }
    }

    const openDropdownDesktop = () => {
        if (window.innerWidth >= 1024) {
            setDropdownOpen(true)
        }
    }

    const closeDropdownDesktop = () => {
        if (window.innerWidth >= 1024) {
            setDropdownOpen(false)
        }
    }

    const fetchData = async () => {
        setLoading(true)
        await axiosReadInstance
            .get(`${API_URL}/api/pages`, {
                params: {
                    sort: 'position:asc',
                    fields: ['url', 'name', 'position'],
                },
            })
            .then((response) => {
                setData(response.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const fetchExpertisePages = async () => {
        await axiosReadInstance
            .get(`${API_URL}/api/expertises-pages?populate=*`)
            .then((response) => {
                setExpertisePages(response.data.data)
            })
    }

    useEffect(() => {
        fetchData()
        fetchExpertisePages()
    }, [])

    useClickOutside(dropdownRef, (event: MouseEvent) => {
        if (window.innerWidth < 1024 && !buttonRef.current?.contains(event.target as Node)) {
            setDropdownOpen(false)
        }
    })

    return (
        <header className="bg-white w-full relative top-0 z-50">
            <div className="container bg-white z-50 px-4 md:px-[6rem] max-w-full flex flex-wrap justify-between items-center py-2 md:py-6">
                <Link to="/" className="flex items-center">
                    <img
                        src={logoLt}
                        className="mr-1 h-6 sm:h-9 self-center"
                        alt="Listen too Logo"
                    />
                    <span
                        className="self-center text-xl font-semibold whitespace-nowrap text-black"
                        translate="no"
                    >
                        Listen too
                    </span>
                </Link>
                <button
                    data-collapse-toggle="navbar-default"
                    type="button"
                    className="inline-flex items-center p-2 ml-3 text-sm text-black-500 rounded-lg lg:hidden hover:bg-gray-100 bg-none focus:outline-none focus:ring-gray-200 text-gray-400 focus:ring-gray-600 transition duration-150 ease-in-out"
                    aria-controls="navbar-collapse"
                    aria-expanded={isOpen ? 'true' : 'false'}
                    onClick={toggleNavbar}
                >
                    {isOpen ? 'Fermer' : 'Ouvrir'}
                    {isOpen ? (
                        <XMarkIcon className="w-6 h-6 ml-2" />
                    ) : (
                        <Bars3Icon className="w-6 h-6 ml-2" />
                    )}
                </button>
                <nav
                    className={`${
                        isOpen ? 'block' : 'hidden'
                    } w-full lg:block lg:w-auto`}
                    id="navbar-collapse"
                >
                    <ul className="flex flex-col text-left text-xl mt-4 lg:flex-row lg:space-x-8 lg:mt-0 lg:text-sm lg:font-medium">
                        {loading ? (
                            <div></div>
                        ) : (
                            data?.data.map((path) => (
                                <li key={path.id} className="relative group">
                                    {path.id === 10 ? (
                                        <>
                                            <div
                                                className="relative"
                                                ref={buttonRef}
                                            >
                                                <Link
                                                    to="/expertises"
                                                    className={`rounded-full py-2 px-4 text-sm font-medium ${
                                                        window.location.pathname.includes(
                                                            '/expertises'
                                                        )
                                                            ? 'force-hover'
                                                            : ''
                                                    }`}
                                                    style={{
                                                        fontSize: '1rem',
                                                        fontWeight: 600,
                                                        lineHeight: '1.4',
                                                    }}
                                                    onClick={toggleDropdownMobile}
                                                    onMouseEnter={openDropdownDesktop}
                                                    onMouseLeave={closeDropdownDesktop}
                                                >
                                                    {path.attributes.name}
                                                </Link>
                                            </div>
                                            <div
                                                className="relative"
                                                onMouseEnter={openDropdownDesktop}
                                                onMouseLeave={closeDropdownDesktop}
                                                ref={dropdownRef}
                                            >
                                                <div
                                                    className={`absolute ${
                                                        dropdownOpen
                                                            ? 'block'
                                                            : 'hidden'
                                                    } left-0 w-64 bg-white border border-gray-200 rounded-md shadow-lg z-10`}
                                                >
                                                    <ul className="py-2">
                                                        {expertisePages.map(
                                                            (expertisePage) => (
                                                                <li
                                                                    key={
                                                                        expertisePage.id
                                                                    }
                                                                    className="hover:bg-gray-100"
                                                                >
                                                                    <Link
                                                                        to={`/expertises/${expertisePage.attributes.urlSlug}`}
                                                                        className="block px-4 py-2 text-sm text-gray-700"
                                                                        onClick={() =>
                                                                            setDropdownOpen(
                                                                                false
                                                                            )
                                                                        }
                                                                    >
                                                                        {hideMarkedText(
                                                                            expertisePage
                                                                                .attributes
                                                                                .title
                                                                        )}
                                                                    </Link>
                                                                </li>
                                                            )
                                                        )}
                                                    </ul>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <Link
                                            to={`/${path.attributes.url}`}
                                            className={`rounded-full py-2 px-4 text-sm font-medium ${
                                                window.location.pathname.includes(
                                                    path.attributes.url
                                                )
                                                    ? 'force-hover'
                                                    : ''
                                            }`}
                                            style={{
                                                fontSize: '1rem',
                                                fontWeight: 600,
                                                lineHeight: '1.4',
                                            }}
                                        >
                                            {path.attributes.name}
                                        </Link>
                                    )}
                                </li>
                            ))
                        )}
                    </ul>
                    <div
                        className="block lg:hidden mt-8 border-b lg:border-0 w-60 shadow-none ring-0"
                        style={{ borderColor: '#dedede' }}
                    ></div>
                </nav>
            </div>
        </header>
    )
}
