import { BlogContent, BlogHeader } from '@components'
import { Helmet } from 'react-helmet-async'
export const Blog = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Listen too | Blog et Actualités - Insights en Digitalisation </title>
                <meta name="description" content="Lisez notre dernier article de blog et nos actualités informatique, de la relation client et collaborateur." />
            </Helmet>
            <BlogHeader />
            <BlogContent />
        </>
    )
}
