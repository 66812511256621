import { API_URL } from '@constants'
import axiosReadInstance from '../../request/axiosReadInstance'
import { useEffect, useState } from 'react'
import { usePageLoading } from '../../context/PageLoadingContext'

interface ICompanyNumbers {
    title: string
    description: string
    shortText: string
    Numbers: [
        {
            number: string
            title: string
        }
    ]
}

export const CompanyNumbers = () => {
    const [companyNumbers, setCompanyNumbers] = useState<ICompanyNumbers>()
    const { incrementLoadingRequestCount, decrementLoadingRequestCount } = usePageLoading();

    const fetchData = async () => {
        incrementLoadingRequestCount();
        await axiosReadInstance
            .get(`${API_URL}/api/vocations?populate=*`)
            .then((response) =>
                setCompanyNumbers(response.data.data[0].attributes)
            ).finally(() => {
                decrementLoadingRequestCount();
            })
    }
    useEffect(() => {
        fetchData()
    }, [])
    return (
        <section className="pt-24 px-6 sm:px-4">
            <div className="max-w-screen-xl mx-auto">
                <p className="font-paragraphbold text-paragraph leading-paragraph text-primary-500 mb-6">
                    {companyNumbers?.shortText}
                </p>
                <h2 className="h2 mb-6">
                    {companyNumbers?.title}
                </h2>
                <p className="paragraph text-neutral-500">
                    {companyNumbers?.description}
                </p>
                <ul className="grid grid-cols-2 sm:grid-cols-5 gap-6 text-center mt-16">
                    {companyNumbers?.Numbers.map((number: any, index) => (
                        <li className="" key={index}>
                            <h3 className="h2 text-primary-500 flex flex-col gap-2 items-center">
                                <span className="flex items-center justify-center w-36 h-36 sm:h-auto sm:w-auto rounded-full lg:w-auto bg-neutral-100 sm:bg-transparent">{number.number}</span>
                                <span className="paragraph text-neutral-500">{number.title}</span>
                            </h3>
                        </li>
                    ))}
                </ul>
            </div>
        </section>
    )
}
