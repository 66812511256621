import { replaceImageExtension } from '@utils'
import { API_URL } from '@constants'
import axiosReadInstance from '../../request/axiosReadInstance'
import { useEffect, useState } from 'react'
import { Image } from '@types'
import { usePageLoading } from '../../context/PageLoadingContext'

interface FetchResults {
    title: string
    image: {
        data: Image[]
    }
}

export const Trust = () => {
    const [images, setImages] = useState<FetchResults>()
    const { incrementLoadingRequestCount, decrementLoadingRequestCount } = usePageLoading();

    const fetchImages = async () => {
        incrementLoadingRequestCount();
        await axiosReadInstance
            .get(`${API_URL}/api/confiances?populate=%2A`)
            .then((response) => {
                response.data.data[0].attributes.image.data.forEach((element: any) => {
                    if(element.attributes.url.includes('.svg')) {
                        element.attributes.url = sanitizeUrl(element.attributes.url)
                    }
                });
                setImages(response.data.data[0].attributes)
            }).finally(() => {
                decrementLoadingRequestCount();
            });
    }

    useEffect(() => {
        fetchImages()
    }, [])

    const sanitizeUrl = (url: string) => {
        const urls = url.split('upload/')
        const result = urls[0].concat('upload/fl_sanitize/', urls[1])
        return result
    }

    return (
        <section className="pt-24 px-6 sm:px-4">
            <div className="max-w-screen-xl mx-auto">
                <h2 className="h2 mb-6">
                    {images?.title}
                </h2>
                <ul className="grid gap-2 grid-cols-3 sm:grid-cols-4 md:grid-cols-6 items-center">
                    {images?.image.data.map((image, index) => (
                        <li key={index}>
                            <img
                                className="img-trust mx-auto w-36 h-26"
                                src={replaceImageExtension(image?.attributes.url || '')}
                                alt={image.attributes.alternativeText}
                            />
                        </li>
                    ))}
                </ul>
            </div>
        </section>
    )
}
