import { API_URL } from '@constants'
import axiosReadInstance from '../../request/axiosReadInstance'
import { useEffect, useState } from 'react'
import { Heading } from '@components/heading/Heading'
import { replaceImageExtension } from '@utils'
import { Image } from '@types'
import { useParams } from 'react-router-dom'
import { usePageLoading } from '../../context/PageLoadingContext'
import { highlightMarkedText } from '@helpers/getMark'

export interface IExpertisesPages {
    title: string
    blockText1: string
    urlSlug: string
    blockText2?: string
    title2?: string
}

export const ExpertiseDetailHeader = () => {
    const { id } = useParams<{ id: string }>()
    const [expertiseHeader, setExpertiseHeader] = useState<IExpertisesPages>()
    const [image, setImage] = useState<Image>()
    const { incrementLoadingRequestCount, decrementLoadingRequestCount } =
        usePageLoading()

    const fetchData = async () => {
        incrementLoadingRequestCount()
        await axiosReadInstance
            .get(
                `${API_URL}/api/expertises-pages?filters[urlSlug][$eq]=${id}&&populate=*`
            )
            .then((response) => {
                setExpertiseHeader(response.data.data[0].attributes)
                setImage(response.data.data[0].attributes.image.data)
            })
            .finally(() => {
                decrementLoadingRequestCount()
            })
    }

    useEffect(() => {
        fetchData()
    }, [id])

    return (
        <>
            <Heading
                title={highlightMarkedText(expertiseHeader?.title)}
                description={expertiseHeader?.blockText1}
                title2={expertiseHeader?.title2}
                blockText2={expertiseHeader?.blockText2}
                imageUrl={replaceImageExtension(image?.attributes?.url || '')}
                imageAlt={image?.attributes?.alternativeText}
            />
        </>
    )
}
