import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import logoLt from '../../assets/logo-lt.svg'

export const ErrorGlobal = () => {
    return (
        <>
        <div className="z-50 fixed px-4 md:px-[6rem] md:py-6">
            <Link to="/" className="flex items-center">
                    <img
                        src={logoLt}
                        className="mr-1 h-6 sm:h-9 self-center"
                        alt="Listen too Logo"
                    />
                    <span
                        className="self-center text-xl font-semibold whitespace-nowrap text-black"
                        translate="no"
                    >
                        Listen too
                    </span>
            </Link>
        </div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Listen too | Page d'erreur - Agence digitale</title>
                <meta name="description" content="Page d'erreur de Listen too, agence informatique" />
            </Helmet>
            <section className="flex items-center h-full p-16">
                <div className="container flex flex-col items-center justify-center px-5 mx-auto my-8">
                    <div className="max-w-md text-center">
                        <h2 className="mb-8 font-semibold text-9xl">
                            <span className="sr-only">Error</span>
                        </h2>
                        <p className="text-2xl font-semibold md:text-3xl">
                            Le site est actuellement indisponible
                        </p>
                        <p className="mt-4 mb-8 ">
                            Veuillez réessayer ultérieurement
                        </p>
                    </div>
                </div>
            </section>
            </>
        )
}
