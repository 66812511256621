import { highlight } from '@helpers';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import rehypeRaw from 'rehype-raw';
import { Button } from '@components/button/button';
import { useContactForm } from '../../hooks';
import { CitySelect, TextField, TextAreaField, TitleAndSocials, ErrorSection, ThanksSection } from '@components/contactForm';
import FileUploaderInput from '@components/inputs/fileUploaderInput';

export const ContactForm = () => {
    const {
        emailSend,
        contactForm,
        cityOptions,
        validation,
        inputFields,
        image,
        submitting,
        error,
        handleInputChange,
        handleSelectChange,
        handleCheckboxChange,
        handleAttachmentChange,
        handleAttachmentRemove,
        handleSubmit,
    } = useContactForm();

    return (
         <section className="relative px-[1rem] pb-8 bg-white">
            {!emailSend && !error && (
                <div
                    className="block lg:grid auto-cols-max mx-auto lg:gap-8 xl:gap-0 sm:grid-cols-1"
                    style={{ gridTemplateColumns: '1fr 2fr' }}
                >
                    <TitleAndSocials title={contactForm?.title || ''} />
                    { contactForm && 
                    <div
                        className="border rounded-2xl mr-2 md:mr-4 text-left col-start-2 sm:order-1"
                        style={{ borderColor: '#dedede' }}
                    >
                        <div className="flex flex-col my-8 mx-4 text-justify font-medium">
                            <form onSubmit={handleSubmit}>
                                {contactForm?.contactMessage?.map((message : any, index: number) => {
                                    if (message.isLT) {
                                        return (
                                            <ReactMarkdown
                                                rehypePlugins={[rehypeRaw]}
                                                className={`isLT flex w-fit max-w-xl border rounded mr-auto my-4 p-2`}
                                                key={index}
                                            >
                                                {message.field}
                                            </ReactMarkdown>
                                        );
                                    } else if (message.title === 'city') {
                                        return (
                                            <CitySelect
                                                key={index}
                                                message={message}
                                                cityOptions={cityOptions}
                                                validation={validation}
                                                handleSelectChange={handleSelectChange}
                                            />
                                        );
                                    } else if (message.title === 'object') {
                                        return (
                                            <div key={index} className="mb-8">
                                                <TextAreaField
                                                    message={message}
                                                    validation={validation}
                                                    handleInputChange={handleInputChange}
                                                />
                                                <FileUploaderInput
                                                    attachments={inputFields.attachments}
                                                    handleAttachmentChange={handleAttachmentChange}
                                                    handleAttachmentRemove={handleAttachmentRemove}
                                                />
                                            </div>
                                        );
                                    } else {
                                        return (
                                            <TextField
                                                key={index}
                                                message={message}
                                                validation={validation}
                                                handleInputChange={handleInputChange}
                                            />
                                        );
                                    }
                                })}
                                <p className="text-xs mt-8 text-justify">
                                    {contactForm?.validationMessage}
                                </p>
                                <div className="text-xs mt-4">
                                    <input
                                        id="optin"
                                        value="false"
                                        type="checkbox"
                                        className="checked:bg-green-500"
                                        onChange={(event) => handleCheckboxChange(event)}
                                    />
                                    <label htmlFor="optin">
                                        {contactForm?.allow}
                                    </label>
                                </div>
                                <Button
                                    type="submit"
                                    disabled={!submitting}
                                    classList="flex mt-8 mx-auto"
                                >
                                    {contactForm?.sendButton}
                                </Button>
                            </form>
                        </div>
                    </div>
                    }
                </div>
            )}
            {emailSend && (
                <ThanksSection
                    thankTitle={contactForm?.thank.replace(
                        'Merci,',
                        highlight('Merci,').replace(
                            'Merci,',
                            '<div>Merci,</div>'
                        )
                    )}
                    imageUrl={image?.attributes.url}
                    imageAlt={image?.attributes.alternativeText}
                />
            )}
            {error && (
                <ErrorSection
                    errorTitle={contactForm?.errorTitle.replace(
                        'Oups,',
                        highlight('Oups,').replace(
                            'Oups,',
                            '<div>Oups, </div>'
                        )
                    )}
                    errorMessage={contactForm?.error}
                    errorContact={contactForm?.errorSubstitutionContact}
                    imageUrl={image?.attributes.url}
                    imageAlt={image?.attributes.alternativeText}
                />
            )}
        </section>
    );
};
