import { createContext, useContext, useState, useCallback } from 'react';

const PageLoadingContext = createContext<{
    incrementLoadingRequestCount: () => void;
    decrementLoadingRequestCount: () => void;
    isPageLoading: boolean;
}>({
    incrementLoadingRequestCount: () => {},
    decrementLoadingRequestCount: () => {},
    isPageLoading: true,
});

export const usePageLoading = () => useContext(PageLoadingContext);

export const PageLoadingProvider = ({ children }: { children: React.ReactNode }) => {
    const [loadingCount, setLoadingCount] = useState(0);

    const incrementLoadingRequestCount = useCallback(() => {
        setLoadingCount((prev) => prev + 1);
    }, []);

    const decrementLoadingRequestCount = useCallback(() => {
        setLoadingCount((prev) => prev - 1);
    }, []);

    const isPageLoading = loadingCount > 0;

    return (
        <PageLoadingContext.Provider value={{ incrementLoadingRequestCount, decrementLoadingRequestCount, isPageLoading }}>
        {children}
        </PageLoadingContext.Provider>
    );
};
