import { ErrorHeader } from '@components/errorHeader/errorHeader';
import { SocialNetworksList } from '@components';

interface ErrorSectionProps {
    errorTitle?: string;
    errorMessage?: string;
    errorContact?: string;
    imageUrl?: string;
    imageAlt?: string;
}

const ErrorSection = ({ errorTitle, errorMessage, errorContact, imageUrl, imageAlt } : ErrorSectionProps) => {
    return (
        <div className="place-self-center max-w-screen-xl mx-auto">
            <ErrorHeader
                title={errorTitle}
                description={
                    `<div>${errorMessage}
                    <a className='text-primary-500 font-semibold underline' href='mailto:${errorContact}'>
                        ${errorContact}
                    </a>
                    </div>`
                }
                imageUrl={imageUrl}
                imageAlt={imageAlt}
            />
            <SocialNetworksList />
        </div>
    );
};

export default ErrorSection;
