import { replaceImageExtension } from '@utils'
import { Button } from '@components/button/button'
import { API_URL } from '@constants'
import axiosReadInstance from '../../request/axiosReadInstance'
import { useEffect, useState } from 'react'
import { usePageLoading } from '../../context/PageLoadingContext'
import { IExpertisesPages } from '@components/expertiseDetailHeader/ExpertiseDetailHeader'

interface ICardMedia {
    data?: {
        attributes: {
            url: string
            alternativeText: string
        }
    }
}

interface ICards {
    title: string
    description: string
    articleLink: string
    cardMedia: ICardMedia
    id: string
    expertises_page: {
        data: {
            attributes: IExpertisesPages
        }
    }
}

interface IExpertiseAttributes {
    title: string
    cards: Array<ICards>
}

interface IExpertisesResponse {
    data: {
        id: number
        attributes: IExpertiseAttributes
    }[]
}

export const ExpertisesCards = () => {
    const [cards, setCards] = useState<Array<ICards>>([])
    const { incrementLoadingRequestCount, decrementLoadingRequestCount } =
        usePageLoading()

    const fetchData = async () => {
        incrementLoadingRequestCount()
        try {
            const response = await axiosReadInstance.get<IExpertisesResponse>(
                `${API_URL}/api/expertises-cards?populate[cards][populate]=*`
            )
            const allExpertises = response.data.data
            const allCards = allExpertises.flatMap(
                (expertise) => expertise.attributes.cards
            )
            setCards(allCards)
        } finally {
            decrementLoadingRequestCount()
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <section className="pt-24 px-6 sm:px-4">
            <div className="max-w-screen-xl mx-auto">
                <h2 className="h2 mb-16">Nos expertises</h2>
                <ul className="mx-auto flex flex-wrap justify-center gap-6">
                    {cards.map((card, index) => (
                        <li
                            className="sm:w-1/2 md:w-[30%] border border-neutral-400 hover:shadow-lg text-center transition duration-500 rounded-4xl flex flex-col items-center p-10 h-full md:min-h-[550px] lg:min-h-[600px]"
                            key={index}
                        >
                            {card.cardMedia?.data?.attributes?.url && (
                                <img
                                    className="mb-6 w-[50px] h-[50px] md:w-[100px] md:h-[100px] lg:w-[100px] lg:h-[100px] object-cover"
                                    src={replaceImageExtension(
                                        card.cardMedia.data.attributes.url
                                    )}
                                    alt={
                                        card.cardMedia.data.attributes
                                            .alternativeText || 'Image'
                                    }
                                />
                            )}
                            <h3 className="h4 mb-6">{card.title}</h3>
                            <p className="paragraph mb-6 text-neutral-500 flex-1">
                                {card.description}
                            </p>
                            <Button
                                variant="secondary"
                                href={`/expertises/${card.expertises_page.data.attributes.urlSlug}`}
                                activeOnClick
                            >
                                En savoir plus
                            </Button>
                        </li>
                    ))}
                </ul>
            </div>
        </section>
    )
}
