import { replaceImageExtension } from '@utils';
import './button.scss';

interface ButtonProps {
    variant?: "primary" | "secondary" | "link";
    href?: string;
    title?: string;
    type?: "button" | "submit" | "reset";
    children?: string;
    classList?: string;
    target?: string;
    rel?: string;
    logo?: string;
    icon?: React.ReactElement;
    iconPosition?: "before" | "after";
    onClick?: () => void;
    disabled?: boolean;
    activeOnClick?: boolean;
}

export const Button = (props: ButtonProps) => {
    const {
        href,
        title = '',
        logo,
        children,
        type = 'button',
        variant = 'primary',
        classList = '',
        target,
        rel,
        icon,
        iconPosition = 'before',
        onClick,
        disabled = false,
        activeOnClick = false,
    } = props;

    const HtmlTag = href ? 'a' : 'button';

    const properties = href
        ? {
              href: href,
              title: target === '_blank' ? title + ' (nouvelle fenêtre)' : title,
              rel: rel,
              target: target,
          }
        : {
              type: type,
              disabled: disabled,
          };

    return (
        <HtmlTag
            {...properties}
            onClick={onClick}
            className={`button button-${variant} ${classList} ${
                activeOnClick && `button-active-on-click-${variant}`
            } ${icon && 'button-icon'} disabled:opacity-75`}
        >
            {icon && iconPosition === 'before' && icon}
            {logo && <img className="w-5" src={replaceImageExtension(logo)} />}
            {!logo}
            {children}
            {icon && iconPosition === 'after' && icon}
        </HtmlTag>
    );
};
