import { useEffect, useState, useRef, useCallback, ChangeEvent } from 'react';
import { fetchCities, fetchContactForm, sendEmail, uploadFile } from '@services';
import { validateContactFormFields } from '@utils';
import { ContactForm, CityOption, Image, InputFields, Validation } from '@types';
import { usePageLoading } from '../../context/PageLoadingContext';

export const useContactForm = () => {
    const [contactForm, setContactForm] = useState<ContactForm>();
    const [validation, setValidation] = useState<Validation>({
        name: '',
        city: '',
        object: '',
        mail: '',
        phone: '',
        knowBy: '',
        newsletter: false,
        attachments: [],
    });
    const [inputFields, setInputFields] = useState<InputFields>({
        name: '',
        city: '',
        object: '',
        mail: '',
        phone: '',
        knowBy: '',
        newsletter: false,
        attachments: [] as File[],
    });
    const [cityOptions, setCityOptions] = useState<CityOption[]>([]);
    const [submitting, setSubmitting] = useState(false);
    const [emailSend, setEmailSend] = useState(false);
    const [image, setImage] = useState<Image>();
    const firstUpdate = useRef(true);
    const [error, setError] = useState(false);
    const { incrementLoadingRequestCount, decrementLoadingRequestCount  } = usePageLoading();

    const handleInputChange = useCallback((event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setInputFields(prevFields => ({ ...prevFields, [name]: value }));
    }, []);

    const handleSelectChange = useCallback((event: any) => {
        setInputFields(prevFields => ({ ...prevFields, city: event.attributes.label }));
    }, []);

    const handleCheckboxChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setInputFields(prevFields => ({ ...prevFields, newsletter: event.target.checked }));
    }, []);

    const handleAttachmentChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = event.target.files || [];
        const fileArray: File[] = Array.from(selectedFiles);
        setInputFields(prevFields => ({ ...prevFields, attachments: fileArray }));
    }, []);

    const handleAttachmentRemove = useCallback((indexToRemove: number) => {
        setInputFields(prevFields => ({
            ...prevFields,
            attachments: prevFields.attachments.filter((_, index) => index !== indexToRemove)
        }));
    }, []);

    const handleSendEmail = useCallback(async (attachmentsInfo: any[], uploadErrorMessage: string | null = null): Promise<void> => {
        const data = {
            ...inputFields,
            attachmentsInfo,
            uploadErrorMessage,
        };
        await sendEmail(data);
    }, [inputFields]);

    const checkValidation = useCallback((): boolean => {
        const { errors, isCorrect } = validateContactFormFields(inputFields);
        setValidation(errors);
        setSubmitting(() => isCorrect);
        return isCorrect;
    }, [inputFields]);

    const handleSubmit = useCallback(async (e: React.FormEvent) => {
        e.preventDefault();

        if (!checkValidation()) return;

        setSubmitting(true);
        const attachmentsInfo = [];
        let uploadErrorMessage = null;

        try {
            if (inputFields.attachments.length > 0) {
                try {
                    const uploadedFile = await uploadFile(inputFields.mail, inputFields.attachments);
                    attachmentsInfo.push(uploadedFile);
                } catch (error: any) {
                    uploadErrorMessage = `Erreur dans l'envoi d'une pièce jointe, veuillez contacter l'émetteur pour récupérer le document`;
                }
            }
            await handleSendEmail(attachmentsInfo, uploadErrorMessage);
            setEmailSend(true);
        } catch (error: any) {
            setError(true);
        } finally {
            setSubmitting(false);
        }
    }, [checkValidation, handleSendEmail, inputFields]);

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        checkValidation()
    }, [inputFields]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                incrementLoadingRequestCount();
                const response = await fetchContactForm();
                setContactForm(response.data.data.attributes);
                setImage(response.data.data.attributes.image?.data);
            } catch (error) {
                console.error('Error while fetching contact form data', error);
            } finally {
                decrementLoadingRequestCount();
            }
        };

        const fetchCitiesOptions = async () => {
            try {
                incrementLoadingRequestCount();
                const response = await fetchCities();
                setCityOptions(response.data.data);
            } catch (error) {
                console.error('Error while fetching city options', error);
            } finally {
                decrementLoadingRequestCount()
            }
        };

        setSubmitting(true);
        fetchData().then(() => fetchCitiesOptions()).finally(() => {
            setSubmitting(false);
        });
        ;
    }, []);

    return {
        emailSend,
        contactForm,
        cityOptions,
        validation,
        inputFields,
        image,
        submitting,
        error,
        setInputFields,
        handleInputChange,
        handleSelectChange,
        handleAttachmentChange,
        handleAttachmentRemove,
        handleCheckboxChange,
        handleSubmit,
    };
};
