import { API_URL } from '@constants'
import axiosReadInstance from '../../request/axiosReadInstance'
import { useEffect, useState } from 'react'
import { Heading } from '@components/heading/Heading'
import { replaceImageExtension } from '@utils'
import { Image } from '@types'
import { usePageLoading } from '../../context/PageLoadingContext'
import { highlightMarkedText } from '@helpers/getMark'

interface IExpertiseHeader {
    description: string
    title: string
    id: number
    pageTarget: string
}

export const ExpertiseHeader = () => {
    const [expertiseHeader, setExpertiseHeader] = useState<IExpertiseHeader>()
    const [image, setImage] = useState<Image>()
    const { incrementLoadingRequestCount, decrementLoadingRequestCount } =
        usePageLoading()

    const fetchData = async () => {
        incrementLoadingRequestCount()
        await axiosReadInstance
            .get(`${API_URL}/api/expertise-headers/8?populate=*`)
            .then((response) => {
                setExpertiseHeader(
                    response.data.data.attributes.ExpertiseHeader
                )
                setImage(response.data.data.attributes.image.data)
            })
            .finally(() => {
                decrementLoadingRequestCount()
            })
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <Heading
            title={highlightMarkedText(expertiseHeader?.title)}
            description={expertiseHeader?.description}
            imageUrl={replaceImageExtension(image?.attributes.url || '')}
            imageAlt={image?.attributes.alternativeText}
        />
    )
}
