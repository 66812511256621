import React from 'react';
import './loader.scss';

export const Loader: React.FC = () => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
      <svg width="200" viewBox="0 -2 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <linearGradient id="myG" fy="0" gradientTransform="rotate(0 .5 .5)">
            <stop offset="0" stopColor="#116421" />
            <stop offset=".25" stopColor="#0A8335">
              <animate attributeName="offset" dur="3s" values="0;1;0" repeatCount="indefinite" />
            </stop>
            <stop offset="1" stopColor="#00A253" />
          </linearGradient>
        </defs>
        <g className="animate-bottom first">
          <rect id="first" width="11.3328" height="2.7598" rx="1.3799" transform="matrix(0.713322 0.700836 -0.713322 0.700836 1.96875 7.05859)" />
        </g>
        <g className="animate-top first">
          <rect id="second" width="11.3328" height="2.7598" rx="1.3799" transform="matrix(0.713322 0.700836 -0.713322 0.700836 11.1631 10.1233)" fill="#00A253" />
        </g>
        <g className="animate-bottom second">
          <rect id="third" width="11.3328" height="2.7598" rx="1.3799" transform="matrix(0.713322 0.700836 -0.713322 0.700836 11.916 4.89795)" fill="#00A253" />
        </g>
        <g className="animate-top second">
          <rect id="fourth" width="7.80964" height="2.7598" rx="1.3799" transform="matrix(0.713322 0.700836 -0.713322 0.700836 13.0049 0.000244141)" fill="#00A253" />
        </g>
      </svg>
    </div>
  );
};
