import { Image } from '@components/products/products'
import { API_URL } from '@constants'
import axiosReadInstance from '../../request/axiosReadInstance'
import logoLt from '../../assets/logo-lt.svg'
import { useEffect, useState } from 'react'
import { replaceImageExtension } from '@utils'
import { usePageLoading } from '../../context/PageLoadingContext'

interface Office {
    logo: Image
    city: string
    zipCode: string
    adresse: string
}

interface OfficesResult {
    title: string
    office: Array<Office>
}

export const Offices = () => {
    const [offices, setOffices] = useState<OfficesResult>();
    const { incrementLoadingRequestCount, decrementLoadingRequestCount } = usePageLoading();

    const fetchData = async () => {
        incrementLoadingRequestCount();
        await axiosReadInstance
            .get(`${API_URL}/api/offices?populate[office][populate]=%2A`)
            .then((response) => setOffices(response.data.data[0].attributes))
            .finally(() => {
                decrementLoadingRequestCount();
            })
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <section className="pt-24 px-6 sm:px-4">
            <div className="max-w-screen-xl mx-auto">
                <h2 className="h2 mb-10">
                    {offices?.title}
                </h2>
                <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 sm:gap-6">
                    {offices?.office.map((office, index) => (
                        <li className="flex flex-col pb-10 items-center md:items-start" key={index}>
                            <img
                                className="w-40 h-40 mb-4 rounded-4xl"
                                src={replaceImageExtension(office.logo.data.attributes.url)}
                                alt={office.logo.data.attributes.alternativeText}
                            />
                            <h3 className="h4 mb-4 flex items-center">
                                <img
                                    src={logoLt}
                                    className="my-1.5 h-6"
                                    aria-hidden='true'
                                />
                                <span translate="no">
                                    Listen too
                                </span>
                                <span className="font-paragraphbold text-paragraph leading-paragraph text-neutral-500 ml-2 border-l border-primary-400 pl-2">
                                    {office.city}
                                </span>
                            </h3>
                            <address className="font-paragraphbold text-paragraph leading-paragraph text-neutral-500">
                                {office.adresse}<br />
                                {`${office.zipCode} ${office.city}`}
                            </address>
                        </li>
                    ))}
                </ul>
            </div>
        </section>
    )
}
