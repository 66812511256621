import { Image } from '@components/products/products'
import { API_URL } from '@constants'
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/solid'
import axiosReadInstance from '../../request/axiosReadInstance'
import Carousel from 'react-simply-carousel'
import { useEffect, useState } from 'react'
import { replaceImageExtension } from '@utils'
import { usePageLoading } from '../../context/PageLoadingContext'

interface Testimonial {
    author: string
    companyName: string
    job: string
    logo: Image
    review: string
    id: number
    pos: number
    isActive: boolean
}

interface ITestimonials {
    title: string
    Testimonial: Array<Testimonial>
}

export const Testimonials = () => {
    const [testimonials, setTestimonials] = useState<ITestimonials>();
    const [activeSlide, setActiveSlide] = useState(0);
    const { incrementLoadingRequestCount, decrementLoadingRequestCount } = usePageLoading();

    const fetchData = async () => {
        incrementLoadingRequestCount();
        await axiosReadInstance
            .get(
                `${API_URL}/api/testimonials?populate[Testimonial][populate]=%2A`
            )
            .then((response) => {
                response.data.data[0].attributes.Testimonial =
                    response.data.data[0].attributes.Testimonial.map(
                        (testimonial: Testimonial) => ({
                            author: testimonial.author,
                            companyName: testimonial.companyName,
                            job: testimonial.job,
                            logo: testimonial.logo,
                            review: testimonial.review,
                            id: testimonial.id,
                            pos: testimonial.id,
                            isActive:
                                testimonial.id >= 1 && testimonial.id <= 3
                                    ? true
                                    : false,
                        })
                    )
                setTestimonials(response.data.data[0].attributes)
            }).finally(() => {
                decrementLoadingRequestCount();
            });
    }

    useEffect(() => {
        fetchData()
    }, [])
    return (
        <section className="pt-24 px-6 sm:px-4">
            <div className="max-w-screen-xl mx-auto">
                <h2 className="h2 mb-10">
                    {testimonials?.title}
                </h2>
                {testimonials && testimonials?.Testimonial.length > 0 && (
                    <Carousel
                        containerProps={{
                            className: '',
                        }}
                        autoplay={false}
                        infinite={true}
                        preventScrollOnSwipe
                        swipeTreshold={60}
                        activeSlideIndex={activeSlide}
                        onRequestChange={setActiveSlide}
                        backwardBtnProps={{
                            children: (
                                <>
                                    <span className="sr-only">Montrer les éléments précédents</span>
                                    <ArrowLeftIcon className="border border-neutral-400 rounded-full p-2 w-16 h-16" />
                                </>
                            ),
                            style: {
                                width: 60,
                                height: 60,
                                minWidth: 60,
                                alignSelf: 'center',
                                marginRight: 24,
                            },
                            className:"hidden md:block"
                        }}
                        forwardBtnProps={{
                            children: (
                                <>
                                    <span className="sr-only">Montrer les éléments suivants</span>
                                    <ArrowRightIcon className="border border-neutral-400 rounded-full p-2 w-16 h-16" />
                                </>
                            ),
                            style: {
                                width: 60,
                                height: 60,
                                minWidth: 60,
                                alignSelf: 'center',
                                marginLeft: 24,
                            },
                            className:"hidden md:block"
                        }}
                        itemsListProps={{
                            style: {
                                gap: '8px',
                                maxWidth: 'fit-content',
                            },
                        }}
                        dotsNav={{
                            show: true,
                            itemBtnProps: {
                                style: {
                                    height: 16,
                                    width: 16,
                                    borderRadius: '50%',
                                    border: 0,
                                    background: '#dedede',
                                    marginLeft: 5,
                                    marginRight: 5,
                                    marginTop: 20,
                                },
                            },
                            activeItemBtnProps: {
                                style: {
                                    height: 16,
                                    width: 16,
                                    borderRadius: '50%',
                                    border: 0,
                                    background: '#0A8335',
                                    marginLeft: 5,
                                    marginRight: 5,
                                    marginTop: 20,
                                },
                            },
                        }}
                        itemsToShow={3}
                        responsiveProps={[
                            
                            {
                                itemsToShow: 2,
                                itemsToScroll: 2,
                                maxWidth: 1200,
                            },
                            {
                                itemsToShow: 1,
                                itemsToScroll: 1,
                                maxWidth: 960,
                            },
                        ]}
                        speed={1000}
                    >
                        {testimonials?.Testimonial.map((testimonial, index) => {
                            return (
                                <div
                                    key={index}
                                    className="border border-neutral-400 rounded-4xl w-[325px] text-center p-10 flex flex-col gap-6"
                                >
                                    <div
                                        className="flex justify-center items-center w-20 h-20 mx-auto border border-neutral-400 rounded-full overflow-hidden"
                                    >
                                        <img
                                            className="max-w-16 h-full object-cover"
                                            src={replaceImageExtension(testimonial.logo.data.attributes.url)}
                                            alt={testimonial.logo.data.attributes.alternativeText}
                                        />
                                    </div>

                                    <h3 className="h4 text-primary-500">
                                        {testimonial.companyName}
                                    </h3>

                                    {testimonial.review && (
                                        <p className="text-center paragraph text-neutral-500 min-h-auto md:min-h-[360px]">
                                            {testimonial.review}
                                        </p>
                                    )}

                                    {testimonial.author && (
                                        <p className="font-paragraphbold text-paragraph leading-paragraph">
                                            {testimonial.author}
                                        </p>
                                    )}

                                    {testimonial.job  && (
                                        <p className="paragraph text-neutral-500">
                                            {testimonial.job}
                                        </p>
                                    )}
                                </div>
                            )
                        })}
                    </Carousel>
                )}
            </div>
        </section>
    )
}
