import { replaceImageExtension } from '@utils'
import { Image } from '@components/products/products'
import { API_URL } from '@constants'
import axiosReadInstance from '../../request/axiosReadInstance'
import { useEffect, useState } from 'react'
import { usePageLoading } from '../../context/PageLoadingContext'
// import classnames from 'classnames'

interface Feedback {
    name: string
    feedback: string
    job: string
    image: Image
    id: number
    company: number
}

interface ITestimonialJob {
    title: string
    testimonialJob: Array<Feedback>
}

export const TestimonialJob = () => {
    const [testimonialJob, setTestimonialJob] = useState<ITestimonialJob>();
    const [currentIndex, setCurrentIndex] = useState(0);
    const [animate, setAnimate] = useState(false);
    const [visibleItems, setVisibleItems] = useState<Feedback[]>([]);
    const [disableNextButton, setDisableNextButton] = useState(false);
    const { incrementLoadingRequestCount, decrementLoadingRequestCount } = usePageLoading();

    const handleNextClick = () => {
        setAnimate(true)
        setTimeout(() => {
            const nextIndex = currentIndex + 1
            setVisibleItems(
                testimonialJob!.testimonialJob.slice(nextIndex, nextIndex + 1)
            )
            if (
                !disableNextButton &&
                testimonialJob!.testimonialJob.length - nextIndex < 2
            ) {
                setDisableNextButton(true)
            }

            setCurrentIndex(nextIndex)
        }, 500)
        setAnimate(false)
    }

    const handlePrevClick = () => {
        const prevIndex = currentIndex - 1

        setVisibleItems(
            testimonialJob!.testimonialJob.slice(prevIndex, prevIndex + 1)
        )
        if (
            disableNextButton &&
            testimonialJob!.testimonialJob.length - prevIndex
        ) {
            setDisableNextButton(false)
        }

        setCurrentIndex(prevIndex)
    }

    const fetchData = async () => {
        incrementLoadingRequestCount();
        await axiosReadInstance
            .get(`${API_URL}/api/testimonial-job?populate=deep`)
            .then((response) => {
                setTestimonialJob(response.data.data.attributes)
                setVisibleItems(
                    response.data.data.attributes.testimonialJob.slice(0, 1)
                )
            })
            .finally(() => {
                decrementLoadingRequestCount();
            });
    }

    useEffect(() => {
        fetchData()
    }, [])
    return (
        <section className="bg-white  ">
            <div className="carousel py-8 px-4 mx-auto max-w-screen-xl text-left lg:py-16 lg:px-12">
                <h2 className="mb-4 text-lg font-semibold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-1xl   ">
                    {testimonialJob?.title}
                </h2>
                <div className="relative overflow-hidden">
                    <div className="flex flex-grow grid-cols-1 sm:grid-cols-3 justify-between absolute top left w-full h-full">
                        <button
                            onClick={handlePrevClick}
                            className="hover:bg-blue-900/75 text-white w-10 h-full text-center opacity-75 hover:opacity-100  z-10 p-0 m-0 transition-all ease-in-out duration-300"
                            disabled={currentIndex <= 0}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-12 w-20 -ml-5"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth={2}
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M15 19l-7-7 7-7"
                                />
                            </svg>
                            <span className="sr-only">Prev</span>
                        </button>
                        <button
                            onClick={handleNextClick}
                            className="hover:bg-blue-900/75 text-white w-10 h-full text-center opacity-75 hover:opacity-100  z-10 p-0 m-0 transition-all ease-in-out duration-300"
                            disabled={disableNextButton}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-12 w-20 -ml-5"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth={2}
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M9 5l7 7-7 7"
                                />
                            </svg>
                            <span className="sr-only">Next</span>
                        </button>
                    </div>
                    <div
                        // mt-10 justify-center relative flex gap-1 overflow-hidden scroll-smooth px-[70px]  snap-x snap-mandatory touch-pan-x z-0
                        className={`px-[70px] grid grid-cols-1 ${
                            animate ? 'animate-in' : ''
                        }`}
                    >
                        {visibleItems?.map((testimonialJob, index) => {
                            return (
                                <div
                                    key={index}
                                    className="grid grid-cols-1 md:grid-cols-2 ml-2 mr-2 relative mdpx-[2rem] snap-start"
                                    style={{ borderColor: '#D6D6DC' }}
                                >
                                    <div
                                        className="flex mb-8 mt-10 p-1"
                                        style={{ borderColor: '#D6D6DC' }}
                                    >
                                        <img
                                            className="h-auto"
                                            style={{ borderColor: '#fff' }}
                                            src={`${replaceImageExtension(testimonialJob.image.data.attributes.url)}`}
                                            alt={testimonialJob.image.data.attributes.alternativeText}
                                        />
                                    </div>
                                    <div className="self-center">
                                        <h3 className="text-sm md:text-base self-center text-left font-primary tracking-tight text-gray-900 min-h-[65px]">
                                            {testimonialJob.feedback}
                                        </h3>
                                        <div className="text-base md:text-3xl font-semibold text-left self-center mt-4">
                                            <p>
                                                {testimonialJob.name + ', ' + testimonialJob.job}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </section>
    )
}
