import { ContactForm } from '@components'
import { Helmet } from 'react-helmet-async'

export const Contact = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Listen too | Contactez notre agence - Experts en Digitalisation</title>
                <meta name="description" content="Contactez notre agence spécialisée en digitalisation de la relation client et collaborateur. Nous sommes à votre écoute pour tout projet de digitalisation." />
            </Helmet>
            <ContactForm />
        </>
    )
}
