import { API_URL } from '@constants'
import axiosReadInstance from '../../request/axiosReadInstance'
import { useEffect, useState } from 'react'
import { highlight } from '@helpers';
import { Heading } from '@components/heading/Heading';
import { usePageLoading } from '../../context/PageLoadingContext';

interface IBlogHeader {
    description: string
    title: string
    id: number
    pageTarget: string
}

export const BlogHeader = () => {
    const [blogHeader, setBlogHeader] = useState<IBlogHeader>();
    const { incrementLoadingRequestCount, decrementLoadingRequestCount } = usePageLoading();

    const fetchData = async () => {
        incrementLoadingRequestCount();
        await axiosReadInstance
            .get(`${API_URL}/api/expertise-headers/4?populate=*`)
            .then((response) => {
                setBlogHeader(response.data.data.attributes.ExpertiseHeader)
            }).finally(() => {
                decrementLoadingRequestCount();
            });
    }
    useEffect(() => {
        fetchData()
    }, [])

    return (
        <Heading
            title={blogHeader?.title.replace('news', highlight('news'))}
            description={blogHeader?.description}
        />
    )
}
