import {
    OfficeRegion,
    RegionContact,
    RegionJoinUs,
    OfficeDetailHeader,
    TestimonialJob,
} from '@components'
import { Helmet } from 'react-helmet-async'

export const OfficeDetail = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Listen too | Nos Bureaux</title>
                <meta name="description" content="meta desc nos bureaux" />
            </Helmet>
            <OfficeDetailHeader />
            <OfficeRegion />
            <RegionContact />
            <RegionJoinUs />
            <TestimonialJob />
        </>
    )
}
