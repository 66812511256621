import { ExpertiseHeader, Trust, History } from '@components'
import { ContactUs } from '@components/contactUs/ContactUs'
import { ExpertisesCards } from '@components/expertisesCards/ExpertisesCards'
import { Helmet } from 'react-helmet-async'

export const Expertise = () => {
    return (
        <>
            <Helmet>
                    <meta charSet="utf-8" />
                    <title>Listen too | Notre Expertise - Digitalisation et Innovation</title>
                    <meta name="description" content="Découvrez nos projets de digitalisation réussis. Expertise en e-commerce, fidélisation, CRM, outils de la relation client, selfcare et solutions conversationnelles." />
            </Helmet>
            <ExpertiseHeader />
            <History />
            <ExpertisesCards />
            <Trust />
            <ContactUs />
        </>
    )
}
