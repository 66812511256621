import { ThanksHeader } from '@components/thanksHeader/thanksHeader';
import { SocialNetworksList } from '@components';

interface ThanksSectionProps {
    thankTitle?: string;
    imageUrl?: string;
    imageAlt?: string;
}

const ThanksSection = ({ thankTitle, imageUrl, imageAlt } : ThanksSectionProps) => {
    return (
        <div className="place-self-center max-w-screen-xl mx-auto">
            <ThanksHeader
                title={thankTitle}
                description=""
                imageUrl={imageUrl}
                imageAlt={imageAlt}
            />
            <SocialNetworksList />
        </div>
    );
};

export default ThanksSection;
