import { API_URL } from '@constants'
import { useParams } from 'react-router-dom'
import axiosReadInstance from '../../request/axiosReadInstance'
import { useEffect, useState } from 'react'
import { replaceImageExtension } from '@utils'
import { Image } from '@types'
import { usePageLoading } from '../../context/PageLoadingContext'

interface IOfficeDetailHeader {
    content: string
    title: string
    id: number
}

export const OfficeDetailHeader = () => {
    const param = useParams()
    const [officeDetailHeader, setOfficeDetailHeader] =
        useState<IOfficeDetailHeader>()
    const [image, setImage] = useState<Image>();
    const { incrementLoadingRequestCount, decrementLoadingRequestCount } = usePageLoading();

    const fetchData = async () => {
        incrementLoadingRequestCount();
        await axiosReadInstance
            .get(`${API_URL}/api/header-regions/${param.id}?populate=deep`)
            .then((response) => {
                setOfficeDetailHeader(
                    response.data.data.attributes.headerRegion
                )
                setImage(response.data.data.attributes.headerRegion.image.data)
            })
            .finally(() => {
                decrementLoadingRequestCount();
            })
    }
    useEffect(() => {
        fetchData()
    }, [])

    return (
        <>
            <section className="bg-white  ">
                <div className="grid grid-cols-1 md:grid-cols-2 px-4 md:px-[6rem] lg:px-[8rem] pb-8 mx-auto lg:gap-8 xl:gap-0">
                    <div className="mr-4 w-[370px] md:w-[575px] mt-12 md:mt-[100px] text-left">
                        <div className="mr-4 flex flex-wrap w-[370px] md:w-[610px] md:mt-[50px] text-left">
                            {officeDetailHeader?.title
                                .split(' ')
                                .map((char) => (
                                    <span
                                        className="mr-4 text-4xl font-semibold tracking-tight leading-none md:text-[78px]"
                                        translate="no"
                                        style={
                                            char === 'Listen' || char === 'too'
                                                ? { color: '#00a253' }
                                                : {}
                                        }
                                    >
                                        {char}
                                    </span>
                                ))}
                        </div>
                        {/* </h1> */}
                        <p className="max-w-2xl w-[370px] mt-8 mb-6 text-gray-500 lg:mb-8 md:text-lg lg:text-sm ">
                            {officeDetailHeader?.content}
                        </p>
                    </div>
                    <div className="justify-self-end md:h-[500px] md:w-[500px]">
                        <img
                            src={`${replaceImageExtension(image?.attributes.url)}`}
                            alt={image?.attributes.alternativeText}
                        />
                    </div>
                </div>
            </section>
        </>
    )
}
