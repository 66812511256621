import { Image } from '@components/products/products'
import { API_URL } from '@constants'
import axiosReadInstance from '../../request/axiosReadInstance'
import logoLt from '../../assets/logo-lt.svg'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { replaceImageExtension } from '@utils'
import { usePageLoading } from '../../context/PageLoadingContext'

interface Office {
    logo: Image
    city: string
    zipCode: string
    adresse: string
}

interface OfficesResult {
    title: string
    office: Array<Office>
}

export const OfficeRegion = () => {
    const param = useParams();
    const [offices, setOffices] = useState<OfficesResult>();
    const { incrementLoadingRequestCount, decrementLoadingRequestCount } = usePageLoading();

    const fetchData = async () => {
        incrementLoadingRequestCount();
        await axiosReadInstance
            .get(`${API_URL}/api/region-offices/${param.id}?populate=deep`)
            .then((response) => setOffices(response.data.data.attributes.regionOffice))
            .finally(() => {
                decrementLoadingRequestCount();
            });
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <section className="bg-white  ">
            <div className="py-8 px-4 md:px-[6rem] mx-auto text-center lg:py-16 lg:px-[8rem]">
                <h3 className="text-left mb-4 text-2xl md:text-4xl font-semibold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-5xl   ">
                    {offices?.title}
                </h3>
                <div className={`mt-12 grid grid-cols-1 md:grid-cols-${offices?.office.length} gap-4`}>
                    {offices?.office.map((office) => (
                        <div className="w-full max-w-sm bg-white rounded-lg  text-left">
                            <div className="flex flex-col items-center md:items-start pb-10">
                                <img
                                    className="mb-3 w-40 h-40 rounded-3xl shadow-lg"
                                    src={replaceImageExtension(office.logo.data.attributes.url)}
                                    alt={office.logo.data.attributes.alternativeText}
                                />
                                <h6 className="mb-1 text-xl font-medium text-gray-900   ">
                                    <a href="/" className="flex items-center">
                                        <img
                                            src={logoLt}
                                            className="my-1.5 h-6"
                                            alt="Listen too Logo"
                                        />
                                        <span className="ml-0.5 self-center text-xl whitespace-nowrap text-black" translate="no">
                                            Listen too
                                        </span>
                                        <span
                                            className="ml-1.5 self-center text-base font-semibold whitespace-nowrap"
                                            style={{ color: '#525260' }}
                                        >
                                            | {office.city}
                                        </span>
                                    </a>
                                </h6>
                                <span className="text-sm text-gray-500 ">
                                    {office.adresse}
                                </span>
                                <span className="text-sm text-gray-500 ">
                                    {`${office.zipCode} ${office.city}`}
                                </span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}
