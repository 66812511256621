import {
    RecruitmentHeader,
    TestimonialCollab,
    Support,
    WhyJoin,
    JoinUs,
    Contact,
} from '@components'
import { Helmet } from 'react-helmet-async'

export const Recruitment = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Listen too | Rejoignez notre agence - Accompagnement collaborateurs</title>
                <meta name="description" content="Rejoignez Listen too et évoluez dans une agence conseil innovante, engagée et centrée sur le bien-être et le développement de ses talents et collaborateurs." />
            </Helmet>
            <RecruitmentHeader />
            <TestimonialCollab />
         {/*<DiscoverOffice /> */}
            <Support />
            <WhyJoin />
            <JoinUs />
            <Contact />
        </>
    )
}
