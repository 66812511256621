import React from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { ContactMessage, Validation } from '@types';

interface TextAreaFieldProps {
    message: ContactMessage;
    validation: Validation;
    handleInputChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const TextAreaField = ({ message, validation, handleInputChange }: TextAreaFieldProps) => {
    return (
        <div className="mb-8">
            <div className="flex w-fit ml-auto my-2">
                {message.content}
            </div>
            <label
                className="flex w-fit ml-auto my-2 text-sm"
                style={{ color: 'rgb(156 163 175)' }}
                htmlFor={`contact-${message.title}`}
            >
                {message.infoText}
            </label>
            <div className="relative flex flex-col w-auto ml-auto mb-4">
                <div className="flex w-fit ml-auto my-2">
                    <div style={{ display: 'inline-block' }}>
                        <div
                            style={{
                                height: '0',
                                marginLeft: '2.6rem',
                                visibility: 'hidden',
                            }}
                        >
                            {message.field}
                        </div>
                        <textarea
                            id={`contact-${message.title}`}
                            name={message.title}
                            className={`border-2 border-secondary-300 rounded py-2 pl-2`}
                            style={{
                                display: 'inline',
                                width: '100%',
                                backgroundColor: '#F7F7F8',
                            }}
                            placeholder={message.field}
                            rows={8}
                            cols={12}
                            wrap="hard"
                            onChange={(event) =>
                                handleInputChange(event)
                            }
                        ></textarea>
                    </div>
                </div>
                {validation[message.title as keyof typeof validation] !== '' && (
                    <div className="absolute right-0 -bottom-5 flex place-content-end text-state-error ">
                        <ExclamationCircleIcon className="mr-1 w-6" />
                        {validation[message.title as keyof typeof validation]}
                    </div>
                )}
            </div>
        </div>
    );
};

export default TextAreaField;
