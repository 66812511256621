export const replaceImageExtension = (
    imageUrl?: string | undefined | null,
    validExtensions = /\.(png|jpe?g)$/i,
    newExtension: string = '.webp'
) => {
    if (!imageUrl) {
        return ''
    }

    return imageUrl.replace(validExtensions, newExtension)
}
