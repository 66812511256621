import { Image } from '@components/products/products'
import { API_URL } from '@constants'
import axiosReadInstance from '../../request/axiosReadInstance'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ArrowLeftIcon } from '@heroicons/react/24/solid'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import { replaceImageExtension } from '@utils'
import { usePageLoading } from '../../context/PageLoadingContext'

type IBlogDetail = {
    content: string
    title: string
    id: number
    creationDate: Date
    image: Image
}
export const BlogDetail = () => {
    const param = useParams();
    const [blogDetail, setblogDetail] = useState<IBlogDetail>();
    const { incrementLoadingRequestCount, decrementLoadingRequestCount } = usePageLoading();

    const markdown = `${blogDetail?.content}`

    const fetchData = async () => {
        if (param.blogDetailId) {
            incrementLoadingRequestCount();
            await axiosReadInstance
                .get(
                    `${API_URL}/api/blog-details/${param.blogDetailId}?populate=deep`
                )
                .then((response) => {
                    setblogDetail(
                        response.data.data.attributes.detail
                    )
                })
                .finally(() => {
                    decrementLoadingRequestCount();
                });
        }
    }
    useEffect(() => {
        fetchData()
    }, [])
    const navigate = useNavigate();
	const goBack = () => {
		navigate(-1);
	}
    return (
        <>
            <section className="bg-white md:px-12  ">
                <div className=" mx-auto md:px-[60px] py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16">
                    <div
                        className="text-left flex font-bold"
                        style={{ color: '#00a253' }}
                    >
                        <button onClick={goBack}  className="">
                            <p className="flex text-xs md:text-base hover:underline">
                                <ArrowLeftIcon className="mr-1 w-4 font-bold" />
                                {'Retour'}
                            </p>
                        </button>
                    </div>
                    <div
                        className="z-10 flex items-center border rounded-[32px] font-bold mt-12 max-w-[1180px] lg:w-[1180px] relative overflow-hidden md:h-[362px] m-auto"
                        style={{ borderColor: 'white' }}
                    >
                        <img
                            className="z-20  w-full"
                            src={`${replaceImageExtension(blogDetail?.image.data.attributes.url)}`}
                            alt={blogDetail?.image.data.attributes.alternativeText}
                        />
                    </div>
                    <div
                        className="z-30 mt-[-150px] relative border mx-auto rounded-3xl py-12 px-6 md:px-[100px] max-w-[360px] md:max-w-[982px] text-left"
                        style={{
                            backgroundColor: 'white',
                            borderColor: 'white',
                        }}
                    >
                        <h1 className="pt-16 mb-16 text-center text-2xl md:text-3xl lg:text-4xl font-semibold tracking-tight leading-none md:text-[52px] md:w-[762px]">
                            {blogDetail?.title}
                        </h1>
                        <p className="mb-8 mt-8 text-sm text-gray-500 md:w-[762px] lg:mb-8 md:text-[16px] flex justify-center">
                            <ReactMarkdown
                                className="text-justify text-sm md:prose"
                                rehypePlugins={[rehypeRaw]}
                            >
                                {markdown || ''}
                            </ReactMarkdown>
                        </p>
                    </div>
                    <div></div>
                </div>
            </section>
        </>
    )
}
