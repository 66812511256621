import { Image } from '@components/products/products'
import { API_URL } from '@constants'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import axiosReadInstance from '../../request/axiosReadInstance'
import {
    remarkDefinitionList,
    defListHastHandlers,
} from 'remark-definition-list'
import { useEffect, useState } from 'react'
import { replaceImageExtension } from '@utils'
import { usePageLoading } from '../../context/PageLoadingContext'

interface RSECommitmentType {
    title: string
    content: string
}

interface IRSECommitment {
    title: string
    background: Image
    rseCommitment: Array<RSECommitmentType>
}

export const RSECommitment = () => {
    const [rseCommitment, setRSECommitment] = useState<IRSECommitment>();
    const { incrementLoadingRequestCount, decrementLoadingRequestCount } = usePageLoading();

    const fetchData = async () => {
        incrementLoadingRequestCount();
        await axiosReadInstance
            .get(`${API_URL}/api/rse-commitment?populate=deep`)
            .then((response) => {
                setRSECommitment(response.data.data.attributes)
            })
            .finally(() => {
                decrementLoadingRequestCount();
            });
    }

    useEffect(() => {
        fetchData()
    }, [])
    return (
        <section className="section-style">
            <div className="py-8 mx-auto text-left lg:py-16 lg:px-[8rem] md:px-[6rem] ">
                <h2 className="mb-4 text-2xl font-semibold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-1xl   ">
                    {rseCommitment?.title}
                </h2>
                <div className="relative overflw-hidden">
                    <div className="mt-12 flex">
                        <div className='hidden lg:block'>
                            <img
                                className="h-full w-auto max-w-[250px]"
                                src={replaceImageExtension(rseCommitment?.background.data.attributes.url)}
                                alt={rseCommitment?.background.data.attributes.alternativeText}
                            />
                        </div>
                        <div
                        // mt-10 justify-center relative flex gap-1 overflow-hidden scroll-smooth px-[70px]  snap-x snap-mandatory touch-pan-x z-0
                        >
                            {rseCommitment?.rseCommitment.map(
                                (commitment, index) => (
                                    <div key={index}>
                                        <div
                                            className={`bloc-content`}
                                            data-letter={
                                                commitment.title.split('')[0]
                                            }
                                            style={{
                                                backgroundColor: '#fff',
                                            }}
                                        >
                                            {/* <div
                                                className="font-semibold text-lg md:text-6xl pr-2 max-w-[65px] md:min-w-[130px] self-center"
                                                style={{ color: '#D6D6DC' }}
                                            >
                                                {
                                                    rseCommitment.title.split(
                                                        ''
                                                    )[0]
                                                }
                                            </div> */}
                                            <div
                                                key={index}
                                                className={`mb-10 relative w-full self-center md:pt-12 md:pl-10 py-1 snap-start`}
                                                style={{
                                                    borderColor: '#E9E9EC',
                                                }}
                                            >
                                                <div style={ window.innerWidth < 1024 ? ( {
                                                    backgroundImage: `url(${rseCommitment?.background.data.attributes.url})`
                                                }) : ({}) }>
                                                <h3
                                                    className={`text-[32px] font-semibold text-left font-semibold md:font-primary tracking-tight text-gray-900`}
                                                    style={ window.innerWidth > 1024 ? ({ color: '#0A8335' }) : ({color: '#fff'})}
                                                >
                                                    {commitment.title}
                                                </h3>
                                                </div>
                                                <div className="text-left mt-4 text-xs">
                                                    <ReactMarkdown
                                                        className="max-w-none md:mr-32 prose text-justify text-sm md:text-base"
                                                        rehypePlugins={[
                                                            rehypeRaw,
                                                        ]}
                                                        remarkPlugins={[
                                                            remarkDefinitionList,
                                                            defListHastHandlers,
                                                        ]}
                                                    >
                                                        {commitment.content ||
                                                            ''}
                                                    </ReactMarkdown>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
