import { API_URL } from '@constants'
import axiosReadInstance from '../../request/axiosReadInstance'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import { useEffect, useState } from 'react'
import { replaceImageExtension } from '@utils'
import { Image } from '@types'
import { usePageLoading } from '../../context/PageLoadingContext'

interface IHistory {
    content: string
    title: string
}

export const History = () => {
    const [history, setHistory] = useState<IHistory>();
    const [image, setImage] = useState<Image>();
    const markdown = `${history?.content}`;
    const { incrementLoadingRequestCount, decrementLoadingRequestCount } = usePageLoading();

    const fetchData = async () => {
        incrementLoadingRequestCount();
        await axiosReadInstance
            .get(`${API_URL}/api/history?populate=deep`)
            .then((response) => {
                setHistory(response.data.data.attributes)
                setImage(response.data.data.attributes.ltBackground.data)
            })
            .finally(() => {
                decrementLoadingRequestCount();
            });
    }
    useEffect(() => {
        fetchData()
    }, [])

    return (
        <>
            <section className="section-style ">
                <div className="lg:pt-6 lg:pb-16 md:px-32">
                    <h1 className="h1-title">{history?.title}</h1>
                    <ReactMarkdown
                        className="h1-subtext"
                        rehypePlugins={[rehypeRaw]}
                    >
                        {markdown || ''}
                    </ReactMarkdown>

                    <p className="text-left text-base"></p>
                    <div className="img-grid">
                        <img
                            src={replaceImageExtension(image?.attributes.url)}
                            alt={image?.attributes.alternativeText}
                        />
                    </div>
                </div>
            </section>
        </>
    )
}
