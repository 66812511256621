import { highlight } from './highlight'

export const highlightMarkedText = (
    htmlString: string | undefined
): string | undefined => {
    const regex = /<mark>(.*?)<\/mark>/g
    let result = htmlString

    result = result?.replace(regex, (match, p1) => {
        return highlight(p1)
    })

    return result
}

export const hideMarkedText = (
    htmlString: string | undefined
): string | undefined => {
    const regex = /<mark>(.*?)<\/mark>/g
    let result = htmlString

    result = result?.replace(regex, (match, p1) => {
        return p1
    })

    return result
}
