import { API_URL } from '@constants'
import axiosReadInstance from '../../request/axiosReadInstance'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ArrowLeftIcon } from '@heroicons/react/24/solid'
import { replaceImageExtension } from '@utils'
import { Image } from '@types'
import { usePageLoading } from '../../context/PageLoadingContext'

type ExpertiseDetail = {
    description: string
    title: string
    id: number
    issues: string
    results: string
    role: string
    tags: string
}

export const DetailExpertise = () => {
    const param = useParams()
    const [expertiseDetail, setexpertiseDetail] = useState<ExpertiseDetail>()
    const [image, setImage] = useState<Image>()
    const [logo, setLogo] = useState<Image>()
    const { incrementLoadingRequestCount, decrementLoadingRequestCount } =
        usePageLoading()

    const fetchData = async () => {
        if (param.caseId) {
            incrementLoadingRequestCount()
            await axiosReadInstance
                .get(
                    `${API_URL}/api/expertise-details/${param.caseId}?populate=*`
                )
                .then((response) => {
                    setexpertiseDetail(
                        response.data.data.attributes.UseCaseDetail
                    )
                    setImage(response.data.data.attributes.image.data)
                    setLogo(response.data.data.attributes.logo.data)
                })
                .finally(() => {
                    decrementLoadingRequestCount()
                })
        }
    }
    useEffect(() => {
        fetchData()
    }, [])

    const navigate = useNavigate()
    const goBack = () => {
        navigate(-1)
    }
    return (
        <>
            <section className="bg-white md:px-12  ">
                <div className=" mx-auto md:px-[60px] py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16">
                    <div
                        className="text-left flex font-bold"
                        style={{ color: '#00a253' }}
                    >
                        <button onClick={goBack} className="">
                            <p className="flex text-xs md:text-base hover:underline">
                                <ArrowLeftIcon className="mr-1 w-4 font-bold" />
                                {'Retour'}
                            </p>
                        </button>
                    </div>
                    <div
                        className="z-10 flex items-center border rounded-[32px] font-bold mt-12 max-w-[360px] md:max-w-[982px] relative overflow-hidden md:h-[362px] m-auto"
                        style={{ borderColor: 'white' }}
                    >
                        <img
                            className="z-20  w-full"
                            src={`${replaceImageExtension(
                                image?.attributes.url
                            )}`}
                            alt={image?.attributes.alternativeText}
                        />
                    </div>
                    <div
                        className="z-30 mt-[-150px] relative border mx-auto rounded-3xl py-12 px-6 md:px-[100px] max-w-[360px] md:max-w-[982px] text-left"
                        style={{
                            backgroundColor: 'white',
                            borderColor: '#ddd',
                        }}
                    >
                        <div className="mx-auto mt-6 mb-12">
                            <img
                                style={{
                                    minWidth: 'fit-content',
                                    maxHeight: '6rem',
                                    maxWidth: '80vw',
                                }}
                                className="mx-auto rounded-md w-[6rem]"
                                src={replaceImageExtension(
                                    logo?.attributes.url
                                )}
                                alt={logo?.attributes.alternativeText}
                            />
                        </div>
                        <h1 className="mb-16 text-center text-2xl md:text-3xl lg:text-4xl font-semibold tracking-tight leading-none md:text-[52px] md:w-[762px]">
                            {expertiseDetail?.title}
                        </h1>
                        <h2
                            className="mt-8 mb-8 text-base md:text-xl lg:text-xl leading-5 md:leading-[45px] font-semibold tracking-tight md:text-[32px] md:w-[762px]"
                            style={{ color: '#0A8335' }}
                        >
                            {expertiseDetail?.tags}
                        </h2>

                        <ReactMarkdown
                            className="prose mb-8 mt-8 text-sm text-gray-500 md:w-[762px] lg:mb-8 md:text-[16px]"
                            rehypePlugins={[rehypeRaw]}
                        >
                            {expertiseDetail?.description || ''}
                        </ReactMarkdown>
                        <h3 className="prose mb-8 mt-8 text-xl font-semibold tracking-tight leading-none md:text-[24px] md:w-[762px]">
                            Les enjeux client
                        </h3>
                        <ReactMarkdown
                            className="prose mb-8 mt-8 text-sm text-gray-500 md:w-[762px] lg:mb-8 md:text-[16px]"
                            rehypePlugins={[rehypeRaw]}
                        >
                            {expertiseDetail?.issues || ''}
                        </ReactMarkdown>
                        <h3 className="prose mb-8 mt-8 text-xl font-semibold tracking-tight leading-none md:text-[24px] md:w-[762px]">
                            Notre rôle
                        </h3>
                        <ReactMarkdown
                            className="prose mb-8 mt-8 text-sm text-gray-500 md:w-[762px] lg:mb-8 md:text-[16px]"
                            rehypePlugins={[rehypeRaw]}
                        >
                            {expertiseDetail?.role || ''}
                        </ReactMarkdown>
                        <h3 className="prose mb-8 mt-8 text-xl font-semibold tracking-tight leading-none md:text-[24px] md:w-[762px]   ">
                            Les résultats
                        </h3>
                        <ReactMarkdown
                            className="prose mb-6 text-gray-500 text-sm md:w-[762px] lg:mb-8 md:text-[16px]"
                            rehypePlugins={[rehypeRaw]}
                        >
                            {expertiseDetail?.results || ''}
                        </ReactMarkdown>
                    </div>
                    <div></div>
                </div>
            </section>
        </>
    )
}
