import { replaceImageExtension } from '@utils'
import { Image } from '@components/products/products'
import { API_URL } from '@constants'
import axiosReadInstance from '../../request/axiosReadInstance'
import { useEffect, useState } from 'react'
import { usePageLoading } from '../../context/PageLoadingContext'

interface ContactCollab {
    image: Image
    name: string
    job: string
    mail: string
    phone: string
}

interface IContact {
    title: string
    description: string
    contactCollab: Array<ContactCollab>
}

export const Contact = () => {
    const [contact, setContact] = useState<IContact>();
    const { incrementLoadingRequestCount, decrementLoadingRequestCount } = usePageLoading();

    const fetchData = async () => {
        incrementLoadingRequestCount();
        await axiosReadInstance
            .get(`${API_URL}/api/contacts/1/?populate=deep`)
            .then((response) => setContact(response.data.data.attributes))
            .finally(() => {
                decrementLoadingRequestCount();
            });
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <section className="section-style">
            <div className="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-[8rem]">
                <h3 className="h1-title">
                    {contact?.title}
                </h3>
                <p className="title-subtext">{contact?.description}</p>
                <div className="mt-12 grid grid-cols-1 md:grid-cols-3 gap-19">
                    {contact?.contactCollab.map((contactCollab, index) => (
                        <div className="w-full mb-12 max-w-sm bg-white rounded-xl background-transparent  text-left" key={index}>
                            <div className="flex flex-cols-2 md:flex-col items-center md:items-start text-left">
                                <div>
                                    <img
                                        className="mb-3 bg-cover bg-center w-[6rem] h-[6rem] md:w-[150px] md:h-[150px] rounded-xl"
                                        src={replaceImageExtension(contactCollab.image.data.attributes.url)}
                                        alt={contactCollab.image.data.attributes.alternativeText}
                                    />
                                </div>
                                <div className="flex flex-col px-2">
                                    <span className="text-2xl font-semibold">
                                        {contactCollab.name}
                                    </span>
                                    <span className="text-sm">
                                        {contactCollab.job}
                                    </span>
                                    <span className="text-sm">
                                        {contactCollab.mail}
                                    </span>
                                    <span
                                        className="mt-1 font-bold text-sm"
                                        style={{ color: '#0A8335' }}
                                    >
                                        {contactCollab.phone}
                                    </span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}
