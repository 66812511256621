import axiosContactInstance from '@request/axiosContactInstance'
import { API_URL } from '@constants'

export const uploadFile = async (
    email: string,
    files: File[]
): Promise<any[]> => {
    const formData = new FormData()
    formData.append('email', email)
    files.forEach((file) => formData.append('file', file))

    try {
        const response = await axiosContactInstance.post(
            `${API_URL}/api/attachments/upload`,
            formData
        )
        return response.data
    } catch (error) {
        throw new Error(`Error while uploading file: ${error}`)
    }
}
