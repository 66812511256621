import { Image } from '@components/products/products'
import { API_URL } from '@constants'
import axiosReadInstance from '../../request/axiosReadInstance'
import { useEffect, useState } from 'react'
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/solid'
import { replaceImageExtension } from '@utils'

interface SocialNetwork {
    title: string
    image: Image
    link: string
}

interface SocialNetworksListResult {
    title: string
    socialNetwork: Array<SocialNetwork>
}

export const SocialNetworksList = () => {
    const [socialNetworksList, setSocialNetworksList] =
        useState<SocialNetworksListResult>()
    const fetchData = async () => {
        await axiosReadInstance
            .get(`${API_URL}/api/social-network-list?populate=deep`)
            .then((response) =>
                setSocialNetworksList(response.data.data.attributes)
            )
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <section className="bg-white  ">
            <div className="py-8    max-w-sm">
                    {socialNetworksList?.title}
                <div className="mt-12 flex flew-wrap justify-between">
                    {socialNetworksList?.socialNetwork.map((socialNetwork, index) => (
                        <div className="w-full max-w-fit bg-white rounded-lg  text-left" key={index}>
                            <a
                                href={socialNetwork.link}
                                className="flex items-center"
                                target="_blank" rel="noreferrer"
                            >
                                <div className="flex flex-col w-full items-center pb-10 text-left">
                                    <img
                                        className="mb-3 h-12"
                                        src={`${replaceImageExtension(socialNetwork.image.data.attributes.url)}`}
                                        alt={`${socialNetwork.image.data.attributes.alternativeText}`}
                                    />
                                        <p className="flex text-sm text-primary-500">
                                            {socialNetwork.title} <ArrowTopRightOnSquareIcon className='w-4 ml-1'/>
                                        </p>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}
