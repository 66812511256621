import { useState, useEffect } from 'react';

export const useDelayedLoading = (isPageLoading: boolean, delay: number) => {
    const [isDelayedLoading, setIsDelayedLoading] = useState(true);

    useEffect(() => {
        let timeout: NodeJS.Timeout;

        if (!isPageLoading) {
            timeout = setTimeout(() => {
                setIsDelayedLoading(false);
            }, delay);
        } else {
            setIsDelayedLoading(true);
        }

        return () => clearTimeout(timeout);
    }, [isPageLoading, delay]);

    return isDelayedLoading;
};
