import { RealisationCases } from '@components/RealisationCases/RealisationCases'
import { RealisationHeader } from '@components/RealisationHeader/RealisationHeader'
import { Helmet } from 'react-helmet-async'

export const Realisation = () => {
    return (
        <>
            <Helmet>
                    <meta charSet="utf-8" />
                    <title>Listen too | Nos Réalisations - Digitalisation et Innovation</title>
                    <meta name="description" content="Découvrez nos projets de digitalisation réussis. Expertise en e-commerce, fidélisation, CRM, outils de la relation client, selfcare et solutions conversationnelles." />
            </Helmet>
            <RealisationHeader />
            <RealisationCases />
        </>
    )
}
