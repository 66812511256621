import { ExpertiseArticleDetail } from '@components'
import { ShouldLike } from '@components'

export const ExpertiseArticleLayout = () => {
    return (
        <>
            <ExpertiseArticleDetail/>
            {/* <ShouldLike /> */}
        </>
    )
}
