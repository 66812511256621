import { API_URL } from '@constants'
import axiosReadInstance from '../../request/axiosReadInstance'
import { useEffect, useState } from 'react'
import decoration from '../../assets/decoration.svg'
import { replaceImageExtension } from '@utils'
import { usePageLoading } from '../../context/PageLoadingContext'

export interface Image {
    data: {
        attributes: {
            name: string
            alternativeText: string
            caption: string
            width: number
            height: number
            hash: string
            ext: string
            mime: string
            size: number
            url: string
            previewUrl: string
            provider: string
            provider_metadata: string
            createdAt: string
            updatedAt: string
        }
    }
}

interface IProducts {
    title: string
    description: string
    caption: string
    right: boolean
    image: Image
}

interface IProductsResults {
    title: string
    produits: Array<IProducts>
}

export const Products = () => {
    const [products, setProducts] = useState<IProductsResults>()
    const { incrementLoadingRequestCount, decrementLoadingRequestCount } = usePageLoading();

    const zeroPad = (num : number, places : number) => String(num).padStart(places, '0')

    const fetchData = async () => {
        incrementLoadingRequestCount();
        await axiosReadInstance
            .get(`${API_URL}/api/produits?populate[produits][populate]=%2A`)
            .then((response) => {
                setProducts(response.data.data[0].attributes)
            }).finally(() => {
                decrementLoadingRequestCount();
            })
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <section className="pt-24 px-6 sm:px-4">
            <div className="max-w-screen-xl mx-auto">
                <h2 className="h2 mb-10">
                    {products?.title}
                </h2>
                <ul className="flex flex-col gap-20">
                    {products?.produits.map((product, index) =>
                        <li className={`flex items-center gap-0 lg:gap-6 flex-col lg:flex-row ${!product.right ? 'lg:flex-row-reverse' : ''}`} key={index}>
                            <img
                                src={replaceImageExtension(product.image.data.attributes.url || '')}
                                alt={product.image.data.attributes.alternativeText}
                            />
                            <div className="relative">
                                <img src={replaceImageExtension(decoration)} className={`hidden lg:block absolute top-0 -z-10`} aria-hidden='true' />
                                <p className="h2 mb-2 text-primary-400">
                                    {zeroPad(index + 1, 2)}
                                </p>
                                <h3 className="h2 mb-8 lg:min-h-7 inline-block">
                                    {product.title}
                                </h3>
                                <p className="paragraph text-neutral-500 mb-6">
                                    {product.description}
                                </p>
                                <p className="font-paragraphbold text-paragraph leading-paragraph text-neutral-500">
                                    {product.caption}
                                </p>
                            </div>
                        </li>
                    )}
                </ul>
            </div>
        </section>
    )
}
