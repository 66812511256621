import { ExpertiseNavigation } from '@components/expertiseCategories/ExpertiseCategories'
import { ExpertiseArticle } from '@components/expertiseArticle/ExpertiseArticle'
import { ExpertiseDetailHeader } from '@components/expertiseDetailHeader/ExpertiseDetailHeader'
import { Helmet } from 'react-helmet-async'

export const ExpertisePage = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Listen too | Nos Expertises - Digitalisation et Innovation
                </title>
                <meta
                    name="description"
                    content="Découvrez nos expertises en transformation digitale. Expertise en e-commerce, cloud, omnicanal, outils collaboratifs et plus encore."
                />
            </Helmet>
            <ExpertiseDetailHeader />
            <ExpertiseArticle />
        </>
    )
}
