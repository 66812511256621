import { DetailExpertise } from '@components'
import { ShouldLike } from '@components'

export const ExpertiseDetail = () => {
    return (
        <>
            <DetailExpertise />
            {/* <ShouldLike /> */}
        </>
    )
}
