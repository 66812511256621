import { Image } from '@components/products/products'
import { API_URL } from '@constants'
import axiosReadInstance from '../../request/axiosReadInstance'
import { useEffect, useState } from 'react'
import moment from 'moment'
import 'moment/locale/fr'
import { ArticleC } from '@components/article/article'
import { replaceImageExtension } from '@utils'
import { usePageLoading } from '../../context/PageLoadingContext'

interface Article {
    title: string
    body: string
    image: Image
    creationDate: string
    blog_detail_id: {
        data: {
            id: number
        }
    }   
}

interface IArticles {
    viewMoreText: string
    knowMoreText: string
    article: Array<Article>
    publishedAt: string
    title: string
}

interface Props {
    title?: boolean
}

export const Articles: React.FC<Props> = ({ title }) => {
    const [articles, setArticles] = useState<IArticles>();
    const { incrementLoadingRequestCount, decrementLoadingRequestCount } = usePageLoading();

    async function fetchData() {
        incrementLoadingRequestCount();
        await axiosReadInstance
            .get(`${API_URL}/api/articles/1?populate=deep`)
            .then((response) => {
                response.data.data.attributes.article =
                response.data.data.attributes.article.slice(0, 3)
                setArticles(response.data.data.attributes)
            }).finally(() => {
                decrementLoadingRequestCount();
            });
    }
    useEffect(() => {
        moment.locale('fr')
        fetchData()
        if (articles) {
            articles.article.length = 2
        }

    }, [])

    return (
        <section className="py-24 px-6 sm:px-4">
            <div className="max-w-screen-xl mx-auto">
                {title && (
                    <h2 className="h2 mb-10">
                        {articles?.title}
                    </h2>
                )}

                <div
                    className={`grid grid-cols-1 md:grid-cols-2 ${
                        window.location.pathname !== '/blog'
                            ? 'md:grid-cols-3'
                            : ''
                    } gap-14 md:gap-4`}
                >
                    {articles?.article.map((art, index) => {
                        
                        let url = `/blog-detail/${art.blog_detail_id.data.id}`;

                        return (
                            <ArticleC
                                url={url}
                                title={art.title}
                                description={art.body}
                                srcImg={`${replaceImageExtension(art.image.data.attributes.url)}`}
                                altImg={art.image.data.attributes.url}
                                subtitle={art.creationDate}
                                knowMoreText={articles.knowMoreText}
                                key={index}
                            />
                        )
                    })}
                </div>
            </div>
        </section>
    )
}
