import { API_URL } from '@constants'
import { useEffect, useState } from 'react'
import axiosReadInstance from '@request/axiosReadInstance'
import { replaceImageExtension } from '@utils'
import { Image } from '@types'
import { usePageLoading } from '../../context/PageLoadingContext'
import { Heading } from '@components/heading/Heading'
import { highlightMarkedText } from '@helpers/getMark'

interface IRecruitmentHeader {
    description: string
    title: string
    id: number
    pageTarget: string
}

export const WhoWeAreHeader = () => {
    const [recruitmentHeader, setRecruitmentHeader] =
        useState<IRecruitmentHeader | null>(null)
    const [image, setImage] = useState<Image | null>(null)
    const { incrementLoadingRequestCount, decrementLoadingRequestCount } =
        usePageLoading()

    useEffect(() => {
        const fetchData = async () => {
            try {
                incrementLoadingRequestCount()
                const response = await axiosReadInstance.get(
                    `${API_URL}/api/expertise-headers/6?populate=*`
                )
                const responseData = response.data.data
                setRecruitmentHeader(responseData.attributes.ExpertiseHeader)
                setImage(responseData.attributes.image.data)
            } catch (error) {
                console.error(
                    'Erreur lors de la récupération des données:',
                    error
                )
            } finally {
                decrementLoadingRequestCount()
            }
        }

        fetchData()
    }, [])

    return (
        <Heading
            title={highlightMarkedText(recruitmentHeader?.title || '')}
            description={recruitmentHeader?.description || ''}
            imageUrl={replaceImageExtension(image?.attributes.url || '')}
            imageAlt={image?.attributes.alternativeText || ''}
        />
    )
}
