import { replaceImageExtension } from '@utils'
import { Image } from '@components/products/products'
import { API_URL } from '@constants'
import axiosReadInstance from '../../request/axiosReadInstance'
import { useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import { usePageLoading } from '../../context/PageLoadingContext'

interface ICommitmentList {
    description: string
    title: string
    slogan: string
    commitment: Array<Commitment>
}

interface Commitment {
    title: string
    content: string
    media?: Image
}

export const CommitmentList = () => {
    const [commitmentList, setCommitmentList] = useState<ICommitmentList>();
    const { incrementLoadingRequestCount, decrementLoadingRequestCount } = usePageLoading();

    const fetchData = async () => {
        incrementLoadingRequestCount();
        await axiosReadInstance
            .get(`${API_URL}/api/commitment-list?populate=deep`)
            .then((response) => {
                setCommitmentList(response.data.data.attributes)
            })
            .finally(() => {
                decrementLoadingRequestCount();
            });
    }
    useEffect(() => {
        fetchData()
    }, [])

    return (
        <>
            <section className="section-style">
                <div className="maw-w-screen-2xl auto-cols-max md:px-[6rem] lg:px-[8rem] pb-8 lg:pt-16 mx-auto lg:gap-8 xl:gap-0">
                    <div className="text-left">
                        <h1 className="h1-title">
                            {commitmentList?.title}
                        </h1>
                        <ReactMarkdown
                            className="text-justify max-w-none mr-32 prose"
                            rehypePlugins={[rehypeRaw]}
                        >
                            {commitmentList?.description || ''}
                        </ReactMarkdown>
                        <h2
                            className="mb-8 mt-10 text-xl md:text-3xl lg:text-4xl font-semibold text-left text-gray-500 mx-auto  "
                            style={{ color: '#00A253' }}
                        >
                            {commitmentList?.slogan}
                        </h2>
                        <div className="flex flex-wrap mt-[30px] text-left text-primary-500">
                            {commitmentList?.commitment.map((commitment, index) => (
                                <div key={index}>
                                    <div className="flex items-start">
                                        <div>
                                            <h3 className="mb-8 text-xl md:text-3xl lg:text-4xl font-semibold text-left mx-auto text-primary-500 ">
                                                {commitment?.title}
                                            </h3>
                                            <ReactMarkdown
                            className="max-w-none md:mr-32 prose text-sm mt-8 text-neutral-500 lg:mb-8 mb-4 text-justify md:text-base "
                            rehypePlugins={[rehypeRaw]}
                        >
                            {commitment?.content || ''}
                        </ReactMarkdown>
                                        </div>
                                        <div>
                                            {commitment.media?.data && (
                                                <img
                                                    src={replaceImageExtension(commitment.media?.data.attributes.url)}
                                                    alt={
                                                        commitment.media?.data
                                                            .attributes.alternativeText
                                                    }
                                                    className="width-full"
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
