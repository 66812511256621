import { replaceImageExtension } from '@utils';
import parse from 'html-react-parser';

interface IHeading {
    HnTitle?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5'
    title?: string
    description?: string
    imageUrl?: string
    imageAlt?: string
}

export const ThanksHeader = (props : IHeading) => {
    const { HnTitle = 'h1', title , description, imageUrl, imageAlt} = props;

    const classListGrid = imageUrl ?
        `grid grid-cols-1 lg:grid-cols-2 gap-8`
        :
        `px-6 sm:px-4 max-w-screen-xl lg:px-12 text-center `;

    const classListTitle = imageUrl ?
        `w-[370px] lg:w-[700px] mt-[50px]`
        :
        ``;

    const classListDescription = imageUrl ?
        `max-w-2xl mt-8 lg:mb-8`
        :
        `mb-8 mt-6 mx-auto max-w-[746px]`;

    return (
            <div className={`max-w-screen-xl mx-auto ${classListGrid}`}>
                <div>
                    <HnTitle className={`h1 ${classListTitle}`}>
                        {title && parse(title)}
                    </HnTitle>
                    <p className={`paragraph ${classListDescription}`}>
                        {description}
                    </p>
                </div>

                {imageUrl && (
                    <div className="justify-self-end md:h-[400px] md:w-[400px]">
                        <img
                            src={replaceImageExtension(imageUrl)}
                            alt={imageAlt}
                            aria-hidden={!imageAlt}
                        />
                    </div>
                )}
            </div>
    )
}