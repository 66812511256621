import { replaceImageExtension } from '@utils'
import { Image } from '@components/products/products'
import { API_URL } from '@constants'
import axiosReadInstance from '../../request/axiosReadInstance'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { usePageLoading } from '../../context/PageLoadingContext'

interface contact {
    image: Image
    name: string
    job: string
    mail: string
    phone: string
    linkedin: string
}

interface Contact {
    title: string
    description: string
    linkedinLogo?: Image
    contacts: Array<contact>
}

export const RegionContact = () => {
    const param = useParams();
    const [allContact, setContact] = useState<Contact>();
    const { incrementLoadingRequestCount, decrementLoadingRequestCount } = usePageLoading();

    const fetchData = async () => {
        incrementLoadingRequestCount();
        await axiosReadInstance
            .get(`${API_URL}/api/region-contacts/${param.id}?populate=deep`)
            .then((response) => setContact(response.data.data.attributes.contacts))
            .finally(() => {
                decrementLoadingRequestCount();
            });
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <section className="bg-white   mt-24">
            <div className="py-8 px-4 md:px-[6rem] mx-auto text-center lg:py-16 lg:px-[8rem]">
                <h3 className="text-left mb-4 text-4xl font-semibold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl   ">
                    {allContact?.title}
                </h3>
                <p className="mt-6 text-xs md:text-base text-left">
                    {allContact?.description}
                </p>
                <div className={`mt-12 grid grid-cols-1 md:grid-cols-${allContact?.contacts.length} gap-4`}>
                    {allContact?.contacts.map((contact, index) => (
                        <div className="w-full p-2 max-w-sm bg-white rounded-lg  text-left">
                            <div className="flex flex-col items-center md:items-start">
                                <img
                                    className="mb-3 w-[150px] h-[150px] rounded-xl shadow-lg"
                                    src={replaceImageExtension(contact.image.data.attributes.url)}
                                    alt={contact.image.data.attributes.alternativeText}
                                />
                                <span className="text-2xl font-semibold text-gray-500 ">
                                    {contact.name}
                                </span>
                                <span className="text-sm text-gray-500 ">
                                    {contact.job}
                                </span>
                                <span className="text-sm text-gray-500 ">
                                    {contact.mail}
                                </span>
                                <span
                                    className="mt-1 font-bold text-sm text-gray-500 "
                                    style={{ color: '#0A8335' }}
                                >
                                    {contact.phone}
                                </span>

                                <a
                                    className="mt-1 flex font-bold text-sm text-gray-500 hover:underline "
                                    style={{ color: '#0f66c2' }}
                                    href={contact.linkedin}
                                >
                                    <img
                                        className="max-w-[1rem] max-h-[1rem]"
                                        src={replaceImageExtension(allContact?.linkedinLogo?.data.attributes.url)}
                                        alt={allContact?.linkedinLogo?.data.attributes.alternativeText}
                                    />
                                    <span className="ml-1">{contact.name}</span>
                                </a>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}
