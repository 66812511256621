import { replaceImageExtension } from '@utils'
import parse from 'html-react-parser'
import rehypeRaw from "rehype-raw";
import ReactMarkdown from "react-markdown";

interface IHeading {
    HnTitle?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5'
    title?: string
    description?: string
    blockText2?: string
    imageUrl?: string
    imageAlt?: string
    title2?: string
}

export const Heading = (props: IHeading) => {
    const {
        HnTitle = 'h1',
        title,
        description,
        imageUrl,
        imageAlt,
        blockText2,
        title2
    } = props

    const classSection = imageUrl ? `` : `flex justify-center`
    const classListGrid = imageUrl
        ? `grid grid-cols-1 md:mt-[50px] lg:grid-cols-2 px-6 md:px-[6rem] lg:px-[2rem] pb-8 lg:pb-16 mx-auto lg:gap-8 xl:gap-0`
        : `text-center flex flex-col items-center justify-center px-8 sm:px-4 md:mt-[50px] max-w-screen-xl lg:px-12`

    const classListTitle = imageUrl ? `w-[370px] lg:w-[35vw] mt-[50px]` : ``

    const classListDescription = imageUrl
        ? `title-subtext text-left`
        : `mb-8 mt-6 mx-auto max-w-[746px]`

    const classListContainer = blockText2
        ? `md:px-12 ${classListGrid}`
        : `px-4 md:px-12 ${classListGrid} `

    return (
        <section className={`mx-auto sm:px-24 pb-8 lg:pb-16 ${classSection}`}>
            <div className={classListContainer}>
                <div className="xl:justify-self-start">
                    <HnTitle className={`h1 ${classListTitle}`}>
                        {title && parse(title)}
                    </HnTitle>
                    <p className={`paragraph ${classListDescription}`}>
                        {description}
                    </p>
                </div>
                {imageUrl && (
                    <div className="xl:justify-self-end md:h-[500px] md:w-[500px]">
                        <img
                            src={replaceImageExtension(imageUrl)}
                            alt={imageAlt}
                            aria-hidden={!imageAlt}
                        />
                    </div>
                )}
            </div>
            {title2 && (
                <div className="px-6 md:px-[6rem] lg:px-[2rem] lg:gap-8 xl:gap-0 w-5/6">
                    <h1 className="h1-title">{title2}</h1>
                </div>
            )}
            {blockText2 && (
                <div className="px-6 md:px-[6rem] lg:px-[2rem] lg:gap-8 xl:gap-0 w-5/6">
                    <ReactMarkdown
                        className="text- text-base"
                        rehypePlugins={[rehypeRaw]}
                    >
                        {blockText2 || ''}
                    </ReactMarkdown>
                </div>
            )}
        </section>
    )
}
