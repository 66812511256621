import { replaceImageExtension } from '@utils'
import { API_URL } from '@constants'
import axiosReadInstance from '../../request/axiosReadInstance'
import { useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import { Image } from '@types'
import { usePageLoading } from '../../context/PageLoadingContext'

interface Value {
    title: string
    description: string
    icon: {
        data: Image
    }
}

interface OurValue {
    title: string
    value: Array<Value>
}

export const OurValues = () => {
    const [allValues, setAllValues] = useState<OurValue>();
    const { incrementLoadingRequestCount, decrementLoadingRequestCount } = usePageLoading();

    const fetchData = async () => {
        incrementLoadingRequestCount();
        await axiosReadInstance
            .get(`${API_URL}/api/our-value?populate=deep`)
            .then((response) => {
                setAllValues(response.data.data.attributes)
            })
            .finally(() => {
                decrementLoadingRequestCount();
            });
    }

    useEffect(() => {
        fetchData()
    }, [])
    return (
        <section className="section-style">
            <div className="lg:pt-6 lg:pb-16 md:px-32 ">
                <h2 className="h1-title">
                    {allValues?.title}
                </h2>
                <div className="relative md:mx-auto overflow-hidden">
                    <div
                        // mt-10 justify-center relative flex gap-1 overflow-hidden scroll-smooth px-[70px]  snap-x snap-mandatory touch-pan-x z-0
                        className={`mt-12 mx-auto grid grid-cols-1 md:grid-cols-2`}
                    >
                        {allValues?.value.map((value, index) => {
                            return (
                                <div
                                    key={index}
                                    className={`px-4 md:px-12 text-center relative w-full snap-start  
                                    ${
                                        index === 0
                                            ? 'pb-6 border-r  md:border-b'
                                            : ''
                                    }
                                    ${index === 1 ? 'md:border-l md:border-b' : ''}
                                    ${index === 2 ? 'md:border-t md:border-r' : ''}
                                    ${index === 3 ? 'md:border-l md:border-t' : ''}
                                    `}
                                    style={{ borderColor: '#D6D6DC' }}
                                >
                                    <div
                                        className="flex justify-center items-center mb-8 mt-6 w-[120px] h-[120px] md:max-h-125px p-1 mx-auto"
                                        style={{ borderColor: '#D6D6DC' }}
                                    >
                                        <img
                                            className="max-w-full"
                                            style={{ borderColor: '#fff' }}
                                            src={`${replaceImageExtension(value.icon.data.attributes.url)}`}
                                            alt={value.icon.data.attributes.alternativeText}
                                        />
                                    </div>

                                    <h3 className="text-2xl text-center font-semibold font-primary tracking-tight text-gray-900">
                                        {value.title}
                                    </h3>
                                    <ReactMarkdown
                            className="prose text-left text-sm text-center mt-4  mb:min-h-[280px]"
                            rehypePlugins={[rehypeRaw]}
                        >
                            {value?.description || ''}
                        </ReactMarkdown>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </section>
    )
}
