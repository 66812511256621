import { API_URL } from '@constants'
import axiosReadInstance from '../../request/axiosReadInstance'
import { useEffect, useState } from 'react'
import { PlayIcon } from '@heroicons/react/24/solid'
import { replaceImageExtension } from '@utils'
import { Image } from '@types'
import { usePageLoading } from '../../context/PageLoadingContext'

interface ITestimonialCollab {
    title: string
    description: string
    collaborator: Array<Collaborator>
}

interface Collaborator {
    name: string
    job: string
    url: string
    image: {
        data: Image
    }
}

export const TestimonialCollab = () => {
    const [testimonialCollab, setTestimonialCollab] =
        useState<ITestimonialCollab>()
    const { incrementLoadingRequestCount, decrementLoadingRequestCount } = usePageLoading();

    const fetchData = async () => {
        incrementLoadingRequestCount();
        await axiosReadInstance
            .get(`${API_URL}/api/testimonial-collab?populate=deep`)
            .then((response) => {
                setTestimonialCollab(response.data.data.attributes)
            })
            .finally(() => {
                decrementLoadingRequestCount();
            });
    }
    useEffect(() => {
        fetchData()
    }, [])

    return (
        <>
            <section className="bg-white mx-auto">
                <div className="py-8 px-8 m-auto xl:pl-[8rem] xl:pr-[16rem]">
                    <div className="z-20 relative">
                        <h1 className="leading-none text-gray-900 mb-8 mt-6 text-2xl lg:text-5xl font-semibold text-left text-gray-500 mx-auto max-w-full  ">
                            {testimonialCollab?.title}
                        </h1>
                        <p className="text-sm md:text-base text-left text-justify ">
                            {testimonialCollab?.description}
                        </p>
                    </div>
                    <div
                        // mt-10 justify-center relative flex gap-1 overflow-hidden scroll-smooth px-[70px]  snap-x snap-mandatory touch-pan-x z-0
                        className={` grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4`}
                    >
                        {testimonialCollab?.collaborator?.map(
                            (collaborator, index) => {
                                return (
                                    <div
                                        key={index}
                                        className="flex w-full snap-start justify-center"
                                        style={{ borderColor: '#D6D6DC' }}
                                    >
                                        <a href={collaborator.url}>
                                            <div>
                                                <img
                                                    className="h-auto md:max-w-[180px] lg:max-w-[200px] xl:max-w-[220px] 2xl:max-w-[280px] mx-auto border rounded-[2rem]"
                                                    style={{
                                                        borderColor: '#fff',
                                                    }}
                                                    src={`${replaceImageExtension(collaborator.image.data.attributes.url)}`}
                                                    alt={collaborator.image.data.attributes.alternativeText}
                                                />
                                                <PlayIcon
                                                    className="relative float-left z-20 border w-[60px] h-[60px] p-4 rounded-bl-[2rem] top-[-60px] xl:left-[0px]"
                                                    style={{
                                                        backgroundColor:
                                                            '#00A253',
                                                        color: '#fff',
                                                        borderColor: '#fff',
                                                        borderWidth: '0px',
                                                    }}
                                                />
                                            </div>

                                            <div className="mt-16 text-sm text-left">
                                                <h3 className=" text-2xl font-semibold text-left tracking-tight">
                                                    {collaborator.name}
                                                </h3>
                                                <span>{collaborator.job}</span>
                                            </div>
                                        </a>
                                    </div>
                                )
                            }
                        )}
                    </div>
                </div>
            </section>
        </>
    )
}
