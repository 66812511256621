import { TrashIcon } from '@heroicons/react/24/solid';

interface FileUploaderProps {
    attachments: File[];
    handleAttachmentChange: (event: React.ChangeEvent<any>) => void;
    handleAttachmentRemove: (index: number) => void;
}

const FileUploaderInput = ({ attachments, handleAttachmentChange, handleAttachmentRemove }: FileUploaderProps) => {

    return (
        <div className="flex flex-col w-fit py-2 ml-auto">
            <label htmlFor="attachment" className="block text-sm font-medium text-gray-700">
                Pièces jointes
            </label>
            <input
                type="file"
                id="attachment"
                multiple
                name="attachment"
                accept=".pdf, .jpeg, .png"
                className="mt-1 py-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                onChange={handleAttachmentChange}
            />
            {attachments.length > 0 && (
                <div className="mt-2">
                    <label className="block text-sm font-medium text-gray-700">Pièces jointes sélectionnées :</label>
                    <ul className="list-disc list-inside">
                        {attachments.map((file, index) => (
                            <li key={index} className="flex items-center text-sm text-gray-500">
                                <span>{file.name}</span>
                                <button
                                    type="button"
                                    onClick={() => handleAttachmentRemove(index)}
                                    className="ml-2 text-sm hover:text-red-500"
                                >
                                    <TrashIcon className="px-2 w-[2rem] h-[2rem]" />
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default FileUploaderInput;
