import { PlayIcon } from '@heroicons/react/24/solid';
import { Button } from '@components/button/button';

interface IArticle {
    HnTitle?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
    url: string;
    title: string;
    description: string;
    srcImg: string;
    altImg: string;
    subtitle: string;
    knowMoreText: string;
    key: number;
}

export const ArticleC: React.FC<IArticle> = (props) => {
    const { HnTitle = 'h3', url, srcImg, altImg, subtitle, title, description, knowMoreText } = props;

    const linkTitle = `Voir l'article sur ${subtitle}`;

    return (
        <article className="flex flex-col gap-4 rounded-6xl">
            <a href={url} title={linkTitle}>
                <div
                    className="rounded-[2.5rem] border w-full"
                    style={{
                        height: "14rem",
                        backgroundImage: `url(${srcImg})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center", // Center the background image
                    }}
                />
            </a>

            <p className="text-lg">{subtitle}</p>

            <a href={url} title={linkTitle}>
                <HnTitle className="h3">{title}</HnTitle>
            </a>

            <p className="text-sm text-neutral-500">{description}</p>

            <Button variant="link" icon={<PlayIcon className="w-4" />} href={url} classList="mt-auto text-primary-400">
                {knowMoreText}
            </Button>
        </article>
    );
};
