import { Helmet } from "react-helmet-async"

export const Error404 = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Listen too | Page d'erreur - Agence digitale</title>
                <meta name="description" content="Page d'erreur de Listen too, agence informatique" />
            </Helmet>
            <section className="flex items-center h-full p-16">
                <div className="container flex flex-col items-center justify-center px-5 mx-auto my-8">
                    <div className="max-w-md text-center">
                        <h2 className="mb-8 font-semibold text-9xl">
                            <span className="sr-only">Error</span>404
                        </h2>
                        <p className="text-2xl font-semibold md:text-3xl">
                            Sorry, we couldn't find this page.
                        </p>
                        <p className="mt-4 mb-8 ">
                            But dont worry, you can find plenty of other things on
                            our homepage.
                        </p>
                        <a
                            rel="noopener noreferrer"
                            href="#"
                            className="px-8 py-3 font-semibold rounded "
                        >
                            Back to homepage
                        </a>
                    </div>
                </div>
            </section>
            </>
        )
}
