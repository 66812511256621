import { API_URL } from '@constants'
import axiosReadInstance from '../../request/axiosReadInstance'
import { useEffect, useState } from 'react'
import { Heading } from '@components/heading/Heading'
import { replaceImageExtension } from '@utils'
import { Image } from '@types'
import { usePageLoading } from '../../context/PageLoadingContext';
import { highlightMarkedText } from '@helpers/getMark'

interface IRealisationHeader {
    description: string
    title: string
    id: number
    pageTarget: string
}

export const RealisationHeader = () => {
    const [realisationHeader, setRealisationHeader] =
        useState<IRealisationHeader>()
    const [image, setImage] = useState<Image>()
    const { incrementLoadingRequestCount, decrementLoadingRequestCount } = usePageLoading();

    const fetchData = async () => {
        incrementLoadingRequestCount()
        await axiosReadInstance
            .get(`${API_URL}/api/expertise-headers/1?populate=*`)
            .then((response) => {
                setRealisationHeader(
                    response.data.data.attributes.ExpertiseHeader
                )
                setImage(response.data.data.attributes.image.data)
            })
            .finally(() => {
                decrementLoadingRequestCount()
            })
    }
    useEffect(() => {
        fetchData()
    }, [])

    return (
        <Heading
            title={highlightMarkedText(realisationHeader?.title)}
            description={realisationHeader?.description}
            imageUrl={replaceImageExtension(image?.attributes.url || '')}
            imageAlt={image?.attributes.alternativeText}
        />
    )
}
