import { Route, Routes, Outlet, useLocation } from 'react-router-dom'
import './App.scss'
import { Footer, Loader, Navbar, PrivacyPolicy} from '@components'
import {
    Error404,
    Contact,
    Blog,
    Home,
    Expertise,
    ExpertisePage,
    Recruitment,
    OfficeDetail,
    WhoWeAre,
    BlogDetailPage,
    Realisation,
    ExpertiseDetail,
    ExpertiseArticleLayout
} from '@layouts'
import { useEffect } from 'react'
import { usePageLoading } from './context/PageLoadingContext'
import { useDelayedLoading } from './hooks/loading/useDelayedLoading'

const pathsWithoutFooter = ['/contact']

const PageLayout = () => {
    const { isPageLoading } = usePageLoading()
    const isDelayedLoading = useDelayedLoading(isPageLoading, 300)
    const { pathname } = useLocation()

    const showFooter = !pathsWithoutFooter.includes(pathname)

    return (
        <>
            <Navbar />
            {isDelayedLoading && <Loader />}
            <div style={{ display: isDelayedLoading ? 'none' : 'block' }}>
                <Outlet />
            </div>
            {showFooter && <Footer />}
        </>
    )
}

function App() {
    const { pathname } = useLocation()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [pathname])

    return (
        <main className="App">
            <Routes>
                <Route element={<PageLayout />}>
                    <Route path="/" element={<Home />} />
                    <Route path="/accueil" element={<Home />} />
                    <Route path="/expertises" element={<Expertise />} />
                    <Route path="/realisation" element={<Realisation />} />
                    <Route path="/expertises/:id" element={<ExpertisePage />} />
                    <Route
                        path="/realisation-detail/:expertiseId/:caseId"
                        element={<ExpertiseDetail />}
                    />
                    <Route
                        path="/expertise-article-detail/:expertiseId/:caseId"
                        element={<ExpertiseArticleLayout />}
                    />
                    <Route path="/nos-metiers" element={<Home />} />
                    <Route path="/actualites" element={<Blog />} />
                    <Route path="/notre-ecosysteme" element={<Expertise />} />
                    <Route path="/nous-rejoindre" element={<Recruitment />} />
                    <Route path="/office/:id" element={<OfficeDetail />} />
                    <Route path="/newsroom" element={<Blog />} />
                    <Route
                        path="/blog-detail/:blogDetailId"
                        element={<BlogDetailPage />}
                    />
                    <Route path="/qui-sommes-nous" element={<WhoWeAre />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="*" element={<Error404 />} />
                    <Route path="/contact" element={<Contact />} />
                </Route>
            </Routes>
        </main>
    )
}

export default App
