import { Image } from '@components/products/products'
import { API_URL } from '@constants'
import axiosReadInstance from '../../request/axiosReadInstance'
import { useEffect, useState } from 'react'
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/solid'
import Carousel from 'react-simply-carousel'
import { replaceImageExtension } from '@utils'
import { usePageLoading } from '../../context/PageLoadingContext'

interface Feedback {
    name: string
    feedback: string
    job: string
    image: Image
    id: number
    company: number
}

interface IWhyJoin {
    title: string
    testimonialJoin: Array<Feedback>
}

export const WhyJoin = () => {
    const [whyJoin, setWhyJoin] = useState<IWhyJoin>()
    const [visibleItems, setVisibleItems] = useState<Feedback[]>([])
    const [activeSlide, setActiveSlide] = useState(0)
    const [isLoading, setIsLoading] = useState(true);
    const { incrementLoadingRequestCount, decrementLoadingRequestCount } = usePageLoading();


    const fetchData = async () => {
        setIsLoading(true);
        incrementLoadingRequestCount();
        await axiosReadInstance
            .get(`${API_URL}/api/why-join?populate=deep`)
            .then((response) => {
                response.data.data.attributes.testimonialJoin =
                    response.data.data.attributes.testimonialJoin.map(
                        (testimonial: Feedback) => ({
                            company: testimonial.company,
                            job: testimonial.job,
                            image: testimonial.image,
                            feedback: testimonial.feedback,
                            id: testimonial.id,
                            name: testimonial.name,
                            isActive:
                                testimonial.id >= 1 && testimonial.id <= 3
                                    ? true
                                    : false,
                        })
                    )
                setWhyJoin(response.data.data.attributes)
                setVisibleItems(response.data.data.attributes.testimonialJoin)
            })        
            .finally(() => {
                setIsLoading(false);
                decrementLoadingRequestCount();
            });
    }

    useEffect(() => {
        if (!isLoading) {
            setActiveSlide(1);
            const timer = setTimeout(() => {
                setActiveSlide(0);
            }, 500);
            return () => clearTimeout(timer);
        }
    }, [isLoading]);
    
    
    useEffect(() => {
        fetchData()
    }, [])
    return (
        <section className="bg-white mb-20">
        <div className="max-w-screen-2xl px-4  lg:py-16">
            <h2 className="mb-4 md:px-[8rem] text-lg font-semibold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-1xl">
                {whyJoin?.title}
            </h2>
            {isLoading ? (
                <p></p>
            ) : (
                whyJoin &&
                whyJoin?.testimonialJoin.length > 0 && (
                    <Carousel
                        containerProps={{
                            className: '',
                        }}
                        infinite={true}
                        centerMode={true}
                        preventScrollOnSwipe
                        swipeTreshold={60}
                        activeSlideIndex={activeSlide}
                        onRequestChange={setActiveSlide}
                        responsiveProps={[
                            {
                                itemsToShow: 1,
                                itemsToScroll: 1,
                                minWidth: 768,
                            },
                        ]}
                        forwardBtnProps={{
                            children: (
                                <ArrowRightIcon
                                    className="hidden lg:block border rounded-full p-2 w-16 h-16"
                                    style={{ borderColor: '#D6D6DC' }}
                                />
                            ),
                            style: {
                                width: 60,
                                height: 60,
                                minWidth: 60,
                                alignSelf: 'center',
                            },
                            className: 'hidden lg:block',
                        }}
                        backwardBtnProps={{
                            children: (
                                <ArrowLeftIcon
                                    className="hidden lg:block border rounded-full p-2 w-16 h-16"
                                    style={{ borderColor: '#D6D6DC' }}
                                />
                            ),
                            style: {
                                width: 60,
                                height: 60,
                                minWidth: 60,
                                alignSelf: 'center',
                            },
                            className: 'hidden md:block',
                        }}
                        dotsNav={{
                            show: true,
                            itemBtnProps: {
                                style: {
                                    height: 16,
                                    width: 16,
                                    borderRadius: '50%',
                                    border: 0,
                                    background: '#dedede',
                                    margin: 5,
                                },
                            },
                            activeItemBtnProps: {
                                style: {
                                    height: 16,
                                    width: 16,
                                    borderRadius: '50%',
                                    border: 0,
                                    background: '#0A8335',
                                    margin: 5,
                                },
                            },
                        }}
                        itemsToShow={1}
                        speed={400}
                    >
                        {visibleItems?.map((testimonialDesire, index) => {
                            return (
                                <div
                                    key={index}
                                    className={`grid grid-cols-1 lg:grid-cols-2 object-cover min-w-[20rem] text-justify md:min-w-[60rem] max-w-fit h-auto ml-2 mr-2 md:pr-12 snap-start pb-8 md:pb-2`}
                                    //  lg:grid-cols-2
                                    style={{ borderColor: '#D6D6DC' }}
                                >
                                    <div
                                        // className="flex mb-8 mt-10 p-1"
                                        style={
                                            window.innerWidth < 1024
                                                ? {
                                                      borderColor: '#D6D6DC',
                                                      alignSelf: 'center',
                                                      padding:
                                                          '4rem 4rem 0rem 4rem',
                                                      justifySelf: 'center',
                                                  }
                                                : {
                                                      borderColor: '#D6D6DC',
                                                      alignSelf: 'center',
                                                      padding: '4rem',
                                                      justifySelf: 'center',
                                                  }
                                        }
                                    >
                                        <img
                                            className="h-auto"
                                            style={{ borderColor: '#fff' }}
                                            src={replaceImageExtension(
                                                testimonialDesire.image.data
                                                    .attributes.url)
                                            }
                                            alt={testimonialDesire.image.data.attributes.alternativeText}
                                        />
                                    </div>
                                    <div className="self-center">
                                        <h3 className="text-sm w-full md:text-base self-center text-justify font-primary tracking-tight text-gray-900 min-h-[65px]">
                                            {testimonialDesire.feedback}
                                        </h3>
                                        <div className="text-sm md:text-3xl font-semibold text-left self-center mt-4">
                                            {testimonialDesire.name + ', '}
                                            {testimonialDesire.job}
                                            {testimonialDesire.company}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                        </Carousel>
                    )
                )}
            </div>
        </section>
    );
};