import { API_URL } from '@constants'
import axiosReadInstance from '../../request/axiosReadInstance'
import { useEffect, useState, useRef } from 'react'
import { highlight } from '@helpers'
import { Heading } from '@components/heading/Heading'
import { replaceImageExtension } from '@utils'
import { Image } from '@types'
import { usePageLoading } from '../../context/PageLoadingContext'
import { highlightMarkedText } from '@helpers/getMark'

interface IHero {
    description: string
    title: string
    id: number
    pageTarget: string
}

export const Hero = () => {
    const [hero, setHero] = useState<IHero | null>(null)
    const [images, setImages] = useState<Image[] | null>(null)
    const { incrementLoadingRequestCount, decrementLoadingRequestCount } =
        usePageLoading()

    const fetchData = async () => {
        try {
            incrementLoadingRequestCount()
            const response = await axiosReadInstance.get(
                `${API_URL}/api/heroes/1?populate=*`
            )
            setHero(response.data.data.attributes.Hero[0])
            setImages(response.data.data.attributes.images.data)
        } catch (error) {
            console.error('Error fetching hero data:', error)
        } finally {
            decrementLoadingRequestCount()
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <section className="">
            <Heading
                title={highlightMarkedText(hero?.title)}
                description={hero?.description}
            />
            <div className="py-8 px-6 sm:px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-12">
                <div className="grid grid-cols-3 gap-2 md:gap-6 min-h-auto sm:min-h-[100px] md:min-h-[250px] md:grid-cols-6 ">
                    {images?.map((image, index) => (
                        <img
                            key={index}
                            src={`${replaceImageExtension(
                                image?.attributes.url
                            )}`}
                            alt={image?.attributes.alternativeText}
                            className={`${
                                window.innerWidth > 1024
                                    ? index % 2 === 0
                                        ? 'mb-8'
                                        : 'mt-8'
                                    : index === 1 || index === 4
                                    ? 'mt-8'
                                    : 'mb-8'
                            } rounded-4xl`}
                        />
                    ))}
                </div>
            </div>
        </section>
    )
}
