import { API_URL } from '@constants'
import axiosReadInstance from '../../request/axiosReadInstance'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { usePageLoading } from '../../context/PageLoadingContext'

interface IPrivacyPolicy {
    title: string
    content: string
}

export const PrivacyPolicy = () => {
    const [privacyPolicy, setPrivacyPolicy] = useState<IPrivacyPolicy>();
    const { incrementLoadingRequestCount, decrementLoadingRequestCount } = usePageLoading();

    const fetchData = async () => {
        incrementLoadingRequestCount();
        await axiosReadInstance
            .get(`${API_URL}/api/privacy-policy?populate=*`)
            .then((response) => {
                setPrivacyPolicy(response.data.data.attributes)
            })
            .finally(() => {
                decrementLoadingRequestCount();
            });
    }
    useEffect(() => {
        fetchData()
    }, [])

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Listen too | Mentions Légales - Agence Digitalisation</title>
                <meta name="description" content="Consultez les mentions légales de notre agence spécialisée en digitalisation de la relation client et collaborateur. Informations sur les conditions d'utilisation." />
            </Helmet>
            <section className="bg-white  ">
                <div className="text-center px-6 md:px-[6rem] lg:px-[8rem] pb-8 mx-auto lg:gap-8 xl:gap-0">
                    <div className="mr-4 md:mt-[100px] text-justify">
                        <div className="mr-4 flex flex-wrap mt-[50px] text-left text-3xl font-semibold" style={{ color: '#0A8335' }}>
                            {privacyPolicy?.title}
                        </div>
                        {/* </h1> */}
                        <div className="mt-8 mb-6 text-gray-500 lg:mb-8 md:text-lg lg:text-sm ">
                        <ReactMarkdown
                            className="prose text-justify"
                            rehypePlugins={[rehypeRaw]}
                        >
                            {privacyPolicy?.content || ''}
                        </ReactMarkdown>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
