import { useEffect, RefObject } from 'react'

/**
 * Custom hook to detect clicks outside of an element.
 * @param ref - Reference to the element to monitor
 * @param handler - Function called when a click is detected outside
 */
export function useClickOutside(ref: RefObject<HTMLElement>, handler: (event: MouseEvent) => void) {
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                handler(event)
            }
        }
        document.addEventListener('mousedown', handleClickOutside)
        
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [ref, handler])
}
