import { Image } from '@components/products/products'
import { API_URL } from '@constants'
import axiosReadInstance from '../../request/axiosReadInstance'
import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { usePageLoading } from '../../context/PageLoadingContext'

interface IJoinUs {
    title: string
    content: string
    button1Text: string
    button2Text: string
    button1Url: string
    button2Url: string
    background: Image
}

export const RegionJoinUs = () => {
    const param = useParams()
    const [joinUs, setJoinUs] = useState<IJoinUs>()
    const { incrementLoadingRequestCount, decrementLoadingRequestCount } = usePageLoading();

    const fetchData = async () => {
        incrementLoadingRequestCount();
        await axiosReadInstance
            .get(`${API_URL}/api/region-join-uses/${param.id}?populate=deep`)
            .then((response) => setJoinUs(response.data.data.attributes.region))
            .finally(() => {
                decrementLoadingRequestCount();
            });
    }

    useEffect(() => {
        fetchData()
    }, [])

    const backgroundImage = {
        backgroundImage:
            'url(' + API_URL + joinUs?.background.data.attributes.url + ')',
    }
    return (
        <section
            className="relative bg-cover bg-center bg-no-repeat max-w md:max-h-[400px] md:h-[350px] text-center"
            style={backgroundImage}
        >
            <div className="relative pb-6 pt-12 sm:px-6 lg:items-center lg:px-8">
                <div className="mx-auto max-w-3xl text-center">
                    <h1 className="bg-gradient-to-r from-green-300 pt-6 via-blue-500 to-purple-600 bg-clip-text text-3xl font-semibold text-transparent sm:text-4xl">
                        {joinUs?.title}
                    </h1>

                    <p className="my-6 sm:text-xs sm:leading-relaxed">
                        {joinUs?.content}
                    </p>

                    <div className="mt-8 flex flex-wrap justify-center gap-4">
                        <a href={joinUs?.button1Url || '/'}>
                            <button
                                className="block w-full rounded-full bg-primary px-12 py-3 text-sm font-medium text-black hover:bg-transparent hover:text-white focus:outline-none focus:ring active:text-opacity-75 sm:w-auto"
                                style={{ color: 'white' }}
                            >
                                {joinUs?.button1Text}
                            </button>
                        </a>
                        {/* {joinUs?.button2Text ? () : ()}
                        <Link to={joinUs?.button2Url || '/'}>
                            <button
                                className="block w-full rounded-full bg-primary px-12 py-3 text-sm font-medium text-black hover:bg-transparent hover:text-white focus:outline-none focus:ring active:text-opacity-75 sm:w-auto"
                                style={{ color: 'white' }}
                            >
                                {joinUs?.button2Text}
                            </button>
                        </Link>
                        <Link to={joinUs?.tertiaryLink || '/'}>
                            <button
                                className="block w-full rounded-full bg-primary px-12 py-3 text-sm font-medium text-black hover:bg-transparent hover:text-white focus:outline-none focus:ring active:text-opacity-75 sm:w-auto"
                                style={{ color: 'white' }}
                            >
                                {joinUs?.tertiaryAction}
                            </button>
                        </Link> */}
                    </div>
                </div>
            </div>
        </section>
    )
}
