import { Validation } from '@/types';

export interface ValidationResult {
    errors: Validation;
    isCorrect: boolean;
}

export const validateContactFormFields = (inputFields: any): ValidationResult => {
    const errors: Validation = {
        name: '',
        city: '',
        object: '',
        mail: '',
        phone: '',
        knowBy: '',
        newsletter: false,
        attachments: [] as string[],
    };
    let isCorrect = true;

    if (!inputFields.name.trim()) {
        errors.name = "Le champ n'a pas été rempli";
        isCorrect = false;
    }

    if (!inputFields.city.trim()) {
        errors.city = "La ville n'a pas été sélectionnée";
        isCorrect = false;
    }

    if (!inputFields.object.trim()) {
        errors.object = "Le champ n'a pas été rempli";
        isCorrect = false;
    }

    const regExp = new RegExp(
        "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$"
    );
    if (!inputFields.mail.trim()) {
        errors.mail = "Le champ n'a pas été rempli";
        isCorrect = false;
    } else if (!regExp.test(inputFields.mail)) {
        errors.mail = "Le champ email n'est pas valide";
        isCorrect = false;
    }

    return { errors, isCorrect };
};
