import { API_URL } from '@constants'
import axiosReadInstance from '../../request/axiosReadInstance'
import { useEffect, useState } from 'react'
import { Heading } from '@components/heading/Heading';
import { Image } from '@types';
import { usePageLoading } from '../../context/PageLoadingContext';
import { highlightMarkedText } from '@helpers/getMark';

interface IRecruitmentHeader {
    description: string
    title: string
    id: number
    pageTarget: string
}

export const RecruitmentHeader = () => {
    const [recruitmentHeader, setRecruitmentHeader] =
        useState<IRecruitmentHeader>();
    const [image, setImage] = useState<Image>();
    const { incrementLoadingRequestCount, decrementLoadingRequestCount } = usePageLoading();

    const fetchData = async () => {
        incrementLoadingRequestCount();
        await axiosReadInstance
            .get(`${API_URL}/api/expertise-headers/3?populate=*`)
            .then((response) => {
                setRecruitmentHeader(
                    response.data.data.attributes.ExpertiseHeader
                )
                setImage(response.data.data.attributes.image.data)
            })
            .finally(() => {
                decrementLoadingRequestCount();
            });
    }
    useEffect(() => {
        fetchData()
    }, [])

    return (
        <Heading
            title={highlightMarkedText(recruitmentHeader?.title)}
            description={recruitmentHeader?.description}
            imageUrl={image?.attributes.url}
            imageAlt={image?.attributes.alternativeText}
        />
    )
}
